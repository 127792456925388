import { useState, useEffect } from 'react';

function Cronometro({ datos, prueba, serie, torneoEspecifico }) {
  const [ running, setRunning ] = useState(false);
  const [ startTime, setStartTime ] = useState(null);
  const [ elapsedTime, setElapsedTime ] = useState(0);

  useEffect(() => {
    if (datos.estado === 'start') {
      setStartTime(Date.now());
      setElapsedTime(0);
      setRunning(true);
    } else if (datos.estado === 'stop') {
      setRunning(false);
      setElapsedTime(Date.now() - startTime);
    }
  }, [datos]);

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setElapsedTime(Date.now() - startTime);
      }, 10); // Actualiza cada 10 ms
    } else if (!running && startTime != null) {
      clearInterval(interval);
      organizarTiempo(datos.tiempo);
    }
    return () => clearInterval(interval);
  }, [running, startTime, datos]);

 //const formatTime = (time) => {
  //  const milliseconds = time % 1000;
  //  const seconds = Math.floor(((time / 1000) % 60));
  //  const minutes = Math.floor((time / (1000 * 60)) % 60);
  //  if (document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}minserie${serie}`) !== null) {
  //    document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}minserie${serie}`).value = minutes;
  //    document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}segserie${serie}`).value = seconds;
  //    document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}miliserie${serie}`).value = milliseconds;
  //  }
  //  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}:${milliseconds < 10 ? '00' : milliseconds < 100 ? '0' : ''}${milliseconds}`;
  //};

  const organizarTiempo = (tiempo) =>{
    if(tiempo !== '0:0:0'){
      const [minutos, segundos, milisegundos] = tiempo.split(':').map(Number);
      const ms = (minutos * 60000) + (segundos * 1000) + milisegundos;
      if (ms >= 0 && ms <= 8000) {
        if (document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}minserie${serie}`) !== null) {
          document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}minserie${serie}`).value = 0;
          document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}segserie${serie}`).value = 0;
          document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}miliserie${serie}`).value = 0;
        }
      }else{
        if (document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}minserie${serie}`) !== null) {
          document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}minserie${serie}`).value = minutos;
          document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}segserie${serie}`).value = segundos;
          document.getElementById(`${prueba - 1}tiempo${datos.carril - 1}miliserie${serie}`).value = milisegundos;
        }
      }
    }

    return tiempo;
  }

  return (
    datos.carril <= parseInt(torneoEspecifico.carril) ?(
      <div className='p-2 text-center mb-2 text-white' style={{border: '1px solid gray', boxShadow: '-2px 2px 2px black', borderRadius: '20px', width: '170px', background: 'rgba(0, 119, 255, 0.9)'}}>
        <div className='d-flex justify-content-around'>
          <span className='mb-1' style={{ fontSize: '18px' }}>{"P - " + prueba} </span>
          <span className='mb-1' style={{ fontSize: '18px' }}>{"S - " + serie} </span>
          <span className='mb-1' style={{ fontSize: '18px' }}>{"C - " + datos.carril} </span>
        </div>
        <hr className='m-0'/>
        <span style={{ fontSize: '30px', fontWeight: 'bold', textShadow: '-1px 1px 1px black' }}>{(running ? "Corriendo" : datos.tiempo ? datos.tiempo : '0:0:0')}</span>
      </div>
    ):(
      <></>
    )
  );
}

export default Cronometro;