import React, { useState, useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import TablaTiempos from './TablaTiempos';
import { abrirBaseDeDatosLocal, backupTiemposLocales, registrarCronometro, traerEstadosCronometros } from '../controllers/configuracionFirebase';
import { planillaToExcel } from '../controllers/xlsx';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { datosAdmin } from '../controllers/administrador';
import Cronometro from './Cronometro';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Relevos from './Relevos';
import toast from 'react-hot-toast';

const Tiempos = ({ idTorneoParticipando, pruebasCompletas, planilla, torneoEspecifico, usuario, listadoCategorias, fechaGoogle}) => {
  
  const formulariosRef = useRef([]);
  const seriesRef = useRef([]);
  const [ datosCronometros, setDatosCronometros ] = useState({});
  const [ cronometrosActivos, setCronometrosActivos ] = useState([]);
  const [ prueba, setPrueba ] = useState(1);
  const [ serie, setSerie] = useState(1);
  const [ nadadoresCarriles, setNadadoresCarriles ] = useState([]);
  const [ indexJornada, setIndexJornada ] = useState(0);
  const [ cantJornada, setCantJornada ] = useState([]);
  const [ backup, setBackup ] = useState([]);
  const [ tiemposRegistrados, setTiemposRegistrados ] = useState([]);
  const [ dialogRelevos, setDialogRelevos ] = useState(false);
  const [ automatico, setAutomatico ] = useState(false);
  const navigate = useNavigate();

  function sacarDeportistas(){
    setNadadoresCarriles(planilla);
  }

  function controlJornadas(){
    const aux = [];
    for (let i = 0; i < pruebasCompletas.length; i++) {
      aux.push(i + 1);
    }
    setCantJornada(aux);
  }

  function sacarIndexJornada(index){
    setIndexJornada(index);
    if(usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS'){
      backupTiemposLocales(idTorneoParticipando, setBackup);
    }
    for (let i = 0; i < document.getElementsByClassName('cro').length; i++) {
      document.getElementsByClassName('cro')[i].value = '0';
    }
  }

  const asignarJPS = () => {
    const auxPrueba = document.getElementById('prueba').value;
    const auxSerie = document.getElementById('serie').value;

    setPrueba(parseInt(auxPrueba));
    setSerie(auxSerie);
    for (let i = 1; i <= 10; i++) {
      registrarCronometro(i, {carril: i, estado: "stop", prueba: auxPrueba, serie: auxSerie, tiempo: '0:0:0'});
    }
    toast.success(`La prueba numero ${auxPrueba} y la serie numero ${auxSerie} se han activado correctamente.`);
  }

  useEffect(() => {
    async function torneoTermino(){
      const date = fechaGoogle;
      const fechaTorneo = new Date(torneoEspecifico.fechaTorneo);
      fechaTorneo.setDate(fechaTorneo.getDate() + 1);
      if(date > fechaTorneo){
        const alerta = Swal.mixin({
          toast: true,
        })
        alerta.fire({
          html: `<div class="w-100 position-relative">
          <img style="width: 100px; position: absolute; top: 33px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
          <p style="z-index: 10; font-family: Poppins" class="mb-0">El torneo ha concluido, por lo que no es posible realizar </br>modificaciones en los </br>tiempos.</div>`,
          confirmButtonColor: 'rgb(18, 53, 248)',
          position: 'top-end',
          padding: 8,
        })
        return navigate("/resultadostiempos");
      }
    }

    torneoTermino();
  }, [])

  useEffect(() => {
    sacarDeportistas();
    controlJornadas();
  }, [planilla])

  useEffect(() => {
    if(usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS'){
      abrirBaseDeDatosLocal(idTorneoParticipando);
    }
  },[usuario])

  useEffect(() =>{
    if(usuario.nombreclub === datosAdmin.nombre || usuario.nombreclub === 'PROWEBSPORTS'){
      backupTiemposLocales(idTorneoParticipando, setBackup);
    }
  },[usuario, indexJornada])

  useEffect(() =>{
    pruebasCompletas.forEach((jor) => {
      jor.forEach((prue) => {

        const backPrue = backup.find((back) => prue.id - 1 === back.id);
        
        if (backPrue) {
          nadadoresCarriles[prue.id - 1].forEach((seri) => {
            seri.nadadores.forEach((nad) => {
              const backNad = backPrue.nadadores.find((backN) => nad.identificacion[0] === backN.identificacion);
    
              if (backNad) {
                nad.tiempoBackup = backNad.tiempo.split(':');
              } else {
                nad.tiempoBackup = ["0", "0", "0"];
              }
            });
          });
        }
      });
    });
  },[backup])

  useEffect(() =>{
    if(backup.length !== 0){
      const auxTiemposRegistrados = [...tiemposRegistrados];
      backup.forEach((back)=>{
        auxTiemposRegistrados[back.id] = back.seriesRegistradas;
      })
      setTiemposRegistrados(auxTiemposRegistrados);
    }
  },[indexJornada, backup])

  useEffect(() => {
    if(torneoEspecifico.cronometros === 'si'){
      traerEstadosCronometros(setDatosCronometros, 1);
      traerEstadosCronometros(setDatosCronometros, 2);
      traerEstadosCronometros(setDatosCronometros, 3);
      traerEstadosCronometros(setDatosCronometros, 4);
      traerEstadosCronometros(setDatosCronometros, 5);
      traerEstadosCronometros(setDatosCronometros, 6);
      traerEstadosCronometros(setDatosCronometros, 7);
      traerEstadosCronometros(setDatosCronometros, 8);
      traerEstadosCronometros(setDatosCronometros, 9);
      traerEstadosCronometros(setDatosCronometros, 10);
    }
  }, []);

  useEffect(() =>{
    function sacarObjetosConEnteros(obj) {
      const auxCronometrosActivos = [];
      for (let key in obj) {
        const valorPrueba = obj[key].prueba;
    
        if (Number.isInteger(valorPrueba)) {
          auxCronometrosActivos.push(obj[key]);
        }
      }

      return auxCronometrosActivos;
    }

    setCronometrosActivos(sacarObjetosConEnteros(datosCronometros));
  },[datosCronometros])

  useEffect(() =>{
    function encontrarObjetoConPrimerEntero(obj) {
      const enteros = [];
      for (let key in obj) {
        const valorPrueba = obj[key].prueba;
    
        // Verificamos si el valor convertido es un número entero
        if (Number.isInteger(valorPrueba)) {
          enteros.push(obj[key]);
        }
      }
      
      return enteros;
    }

    function buscarIndexJornada(prueba){
      pruebasCompletas.forEach((prue, indexPrue)=>{
        const auxPruebaEncontrada = prue.filter((auxPrue) => auxPrue.id === parseInt(prueba));
        if(auxPruebaEncontrada.length !== 0){
          if(indexPrue !== indexJornada){
            setIndexJornada(indexPrue);
          }
        }
      })
    }

    const carrilMitad = Math.round(encontrarObjetoConPrimerEntero(datosCronometros).length / 2);

    if(carrilMitad !== 0){
      document.getElementById('prueba').value = datosCronometros[carrilMitad].prueba;
      document.getElementById('serie').value = datosCronometros[carrilMitad].serie;
      setPrueba(datosCronometros[carrilMitad].prueba);
      setSerie(datosCronometros[carrilMitad].serie);
      buscarIndexJornada(datosCronometros[carrilMitad].prueba);
    }else{
      if(datosCronometros[1]){
        setPrueba(datosCronometros[1].prueba);
        setSerie(datosCronometros[1].serie);
        buscarIndexJornada(datosCronometros[1].prueba);
      }
    }
  },[datosCronometros])

  useEffect(() =>{
    function activarEnvioFormulario() {
      const cronometrosEnStop = cronometrosActivos.every((cro)=> cro.tiempo !== '0:0:0' && cro.estado === 'stop');
      if (formulariosRef?.current[parseInt(prueba)] && planilla[prueba - 1]?.length !== 0 && cronometrosEnStop && automatico) {
        formulariosRef.current[parseInt(prueba)].dispatchEvent(new Event("submit", { cancelable: true, bubbles: true })); // Disparar el evento de submit
        if(seriesRef.current[`${parseInt(prueba)}-${serie + 1}`]){
          seriesRef.current[`${parseInt(prueba)}-${serie + 1}`].scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }
    activarEnvioFormulario();
  },[indexJornada, cronometrosActivos])

  return (
    <>
      <motion.div
      initial={{ opacity: 0}}
      animate={{ opacity: 1}}
      transition={{duration: 1}}
      >
        <ul className="nav nav-tabs">
          {cantJornada.length !== 0 ?(
            cantJornada.map((jornada, indexJor)=>{
              return <li className="nav-item" key={indexJor}>
              <button className={`nav-link ${jornada === (indexJornada + 1) ? "active" : ""}`}
              onClick={() => sacarIndexJornada(indexJor)}
              >{`Jornada ${jornada}`}</button>
            </li>
            })
          ):(
            <></>
          )}
          <li className='nav-item'>
            <button className={`nav-link`}
            onClick={() => setDialogRelevos(true)}
            >{`Relevos`}</button>
          </li>
          <li className='nav-item'>
            <button className={`nav-link`}
            onClick={() => planillaToExcel(pruebasCompletas, nadadoresCarriles)}
            >{`Programación a Excel`}</button>
          </li>
        </ul>
        {torneoEspecifico.cronometros === 'si' ?(
          <div className="sticky-top w-100">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    Asignar Prueba y Serie
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <div className='d-flex justify-content-start'>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onClick={() => setAutomatico(!automatico)}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Automático</label>
                      </div>
                    </div>
                    <div className='d-flex flex-wrap justify-content-center'>
                      <div className="form-floating">
                        <input className='form-control mb-2' id='prueba' type="number" defaultValue={prueba} />
                        <label htmlFor={`prueba`}>No. Prueba</label>
                      </div>
                      <div className="form-floating">
                        <input className='form-control mb-2' id='serie' type="number" defaultValue={serie} />
                        <label htmlFor={`serie`}>No. Serie</label>
                      </div>
                    </div>
                    <hr />
                    <Button icon='pi pi-check-circle' label='Asignar Prueba y Serie' className='btn btn-success mt-2' onClick={() => asignarJPS()}></Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-wrap justify-content-around aling-items-center mt-1'>
              {Object.keys(datosCronometros).map((carril) => (
                <Cronometro
                  key={carril}
                  datos={datosCronometros[carril]}
                  prueba={prueba}
                  serie={serie}
                  torneoEspecifico={torneoEspecifico}
                />
              ))}
            </div>
          </div>
        ):(
          <></>
        )}
        {cantJornada.length !== 0 ?(
          <TablaTiempos pruebas={pruebasCompletas[indexJornada]} 
          nadadores={nadadoresCarriles.length !== 0 ? nadadoresCarriles : []} 
          idTorneoParticipando={idTorneoParticipando} 
          torneoEspecifico={torneoEspecifico}
          tiemposRegistrados={tiemposRegistrados} 
          setTiemposRegistrados={setTiemposRegistrados}
          listadoCategorias={listadoCategorias}
          formulariosRef={formulariosRef}
          seriesRef={seriesRef}/>
        ):(
          <></>
        )}
      </motion.div>
      <Dialog visible={dialogRelevos} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Inscripción Relevos`} modal className="p-fluid" onHide={() => setDialogRelevos(false)}>
        <div className="field">
          <Relevos usuario={usuario} 
            idTorneoParticipando={idTorneoParticipando}
            torneoEspecifico={torneoEspecifico}
            pruebasCompletas={pruebasCompletas}
            setNadadoresCarriles={setNadadoresCarriles}
            planilla={planilla}/>
        </div>
      </Dialog>
    </>
  );
}

export default Tiempos;