import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { IoMdContacts, IoMdPerson, IoLogoFacebook, IoLogoWhatsapp, IoMdMail, IoLogoInstagram, IoLogoYoutube, IoIosPaperPlane } from 'react-icons/io'

import { datosAdmin } from '../controllers/administrador';

const Conctatenos = () => {

  const anioAutor = new Date();

  return (
    <motion.div className='w-100 text-start'
    style={{ background: 'linear-gradient(-60deg, #abc7da 50%, rgb(219, 231, 240) 50%)', maskImage: 'linear-gradient(to top,rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%)'}}
    initial={{opacity: 0}}
    whileInView={{opacity: 1}}
    transition={{duration: 1, delay: 0.3}}
    >
      <h4 className='p-3 pt-5 mb-0' style={{fontFamily: 'Anton SC'}}>NO DUDES EN COMUNICARTE CON NOSOTROS PARA CUALQUIER ASISTENCIA QUE NECESITES.</h4>
      <div className='d-flex flex-wrap justify-content-around'>
        <div className='m-3' style={{maxWidth: '200px'}}>
          <img className='w-100' src={require('../img/pwsmascota-izquierda.png')} alt="img logo swimming" style={{filter: "drop-shadow(-1px 1px 2px black)"}}/>
        </div>
        <div className='m-3'>
          <div className='d-flex justify-content-start align-items-center'>
            <img className='me-1' style={{width: '40px'}} src={require('../img/img_logo.png')} alt="logo" />
            <h5 style={{fontFamily: 'Anton SC', letterSpacing: '1px'}}>{datosAdmin.nombre}</h5>
          </div>
          <div className='d-flex flex-column'>
            <Link to={`mailto:${datosAdmin.email}`} target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoMdMail className='fs-2 text-secondary'/> {datosAdmin.email}</Link>
            <Link to={`https://wa.me/57${datosAdmin.celCorto}`} target='_blank' style={{textDecoration: 'none'}} className='text-dark'><IoLogoWhatsapp className='fs-2 text-success'/> +57 {datosAdmin.cel}</Link>
          </div>
          <p className='mb-0 mt-4' style={{fontFamily: 'Gothic A1'}}><b>© 2023 - {anioAutor.getFullYear()} PROWEBSPORTS</b></p>
          <div className='d-flex justify-content-center align-items-center'>
            <Link to="mailto:pws@prowebsports.com.co" target='_blank' style={{textDecoration: 'none'}}><IoMdMail className='fs-3 text-secondary'/></Link>
            <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}}><IoLogoWhatsapp className='fs-3 text-success'/></Link>
            <Link to="https://t.me/573245884041" target='_blank' style={{textDecoration: 'none'}}><IoIosPaperPlane className='fs-3 text-info'/></Link>
            <Link to="https://www.facebook.com/emanuel.barbosa.908132" target='_blank' style={{textDecoration: 'none'}}><IoLogoFacebook className='fs-3 text-primary'/></Link>
            <Link to="https://www.instagram.com/prowebsports/" target='_blank' style={{textDecoration: 'none'}}><IoLogoInstagram className='fs-3' style={{color: 'purple'}}/></Link>
            <Link to="https://www.youtube.com/@emanuelbarbosa2735" target='_blank' style={{textDecoration: 'none'}}><IoLogoYoutube className='fs-3 text-danger'/></Link>
          </div>
        </div>
        <div className='m-3'>
          <h6><IoMdContacts className='fs-2 text-info'/>Equipo</h6>
          <div className='d-flex flex-column'>
            <p className='m-0' style={{fontFamily: 'Gothic A1'}}><IoMdPerson className='fs-5 text-secondary'/> Admon. {datosAdmin.encargado}.</p>
            <Link to={`https://wa.me/57${datosAdmin.celCorto}`} target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 {datosAdmin.cel}</Link>
            <p className='m-0' style={{fontFamily: 'Gothic A1'}}><IoMdPerson className='fs-5 text-secondary'/>Ing. Emanuel Barbosa Labrada.</p>
            <Link to="https://wa.me/573245884041" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 324-588-4041</Link>
            <p className='m-0' style={{fontFamily: 'Gothic A1'}}><IoMdPerson className='fs-5 text-secondary'/>Ing. Ywrani Barbosa Labrada.</p>
            <Link to="https://wa.me/573209147444" target='_blank' style={{textDecoration: 'none'}} className='text-dark'>+57 320-914-7444</Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Conctatenos;