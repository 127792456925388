import jsPDF from 'jspdf';
import { datosAdmin } from './administrador';

export function facturacion(afiliado, setPdfUrl, numeracion, descripcion, setDescripcion, setAfiliadoPago, precios, personalizado, bono, entradas){
    const doc = new jsPDF({
      format: 'letter'
    });

    doc.internal.pageSize.height = 146;
    // Agregar el logo de la empresa como marca de agua
    const logoImg = new Image();
    logoImg.src = require('../img/marca-de-agua.png');
    const width = 170;
    const height = 170;
    doc.addImage(logoImg, 'png', 20, 0, width, height);

    // Configurar la fuente y el tamaño
    doc.setFont('arial', 'italic', 'bold');
    doc.setFontSize(11);
    
    // Título del torneo
    doc.text(`CLUB DEPORTIVO ESCUELA DE NATACIÓN\n${datosAdmin.nombre}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
    doc.setFont('arial');
    doc.setFontSize(20);
    doc.text(`FACTURA`, doc.internal.pageSize.width / 2, 21, { align: 'center' });
    doc.line(20, 23, 190, 23);
    doc.line(20, 140, 190, 140);

    //estado de la factura
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(15);
    doc.text(`Estado: Pagado`, 20, 22, { align: 'left' });

    //Numeracion factura
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(15);
    doc.text(`${numeracion}`, 190, 22, { align: 'right' });

    //NIT orcas valle
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`NIT: 6253227-2`, doc.internal.pageSize.width / 2, 27, { align: 'center' });

    //factura Cliente
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Factura a:`, 20, 40, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${afiliado.nombre}`, 38, 40, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 20, 44, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(`${afiliado.cel}`, 34, 44, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(``, 20, 48, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(``, 34, 48, { align: 'left' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(``, 20, 52, { align: 'left' });
    doc.setFont('arial', '', '');
    doc.text(``, 46, 52, { align: 'left' });
    doc.line(20, 54, 190, 54);
    doc.line(20, 270, 190, 270);

    //ORCAS VALLE
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Empresa:`, 148, 40, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`ORCAS VALLE`, 175, 40, { align: 'right' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`E-mail:`, 144.5, 44, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`esnorcas@hotmail.com`, 182, 44, { align: 'right' });

    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Celular:`, 146, 48, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`3137394026`, 166, 48, { align: 'right' });
    
    const hoy = new Date();
    const fechaString = hoy.toLocaleDateString();
    doc.setFont('arial', '', 'bold');
    doc.setFontSize(11);
    doc.text(`Fecha Factura:`, 157, 52, { align: 'right' });
    doc.setFont('arial', '', '');
    doc.text(`${fechaString}`, 176, 52, { align: 'right' });


    if(parseInt(precios.insc) === 0 && parseInt(precios.mensu) === 0 && parseInt(bono) > 0){
      setDescripcion(`Abono`);
    }
    if(parseInt(precios.insc) > 0 && parseInt(precios.mensu) === 0 && parseInt(entradas) === 0 && parseInt(bono) === 0){
      setDescripcion('Inscripcion');
    }
    if(parseInt(precios.mensu) > 0 && parseInt(precios.insc) === 0 && parseInt(entradas) === 0 && parseInt(bono) === 0){
      setDescripcion('Mensualidad');
    }
    if(parseInt(entradas) > 0 && parseInt(precios.insc) === 0 && parseInt(precios.mensu) === 0 && parseInt(bono) === 0){
      setDescripcion('Entradas');
    }
    if(parseInt(precios.insc) > 0 && parseInt(precios.mensu) > 0){
      setDescripcion(`Inscripcion y\nMensualidad`);
    }
    if(parseInt(precios.insc) > 0 && parseInt(precios.mensu) > 0 && parseInt(bono) > 0){
      setDescripcion(`Inscripcion y\nMensualidad y\nAbono`);
    }
    if(parseInt(precios.insc) > 0 && parseInt(precios.mensu) > 0 && parseInt(entradas) > 0){
      setDescripcion(`Inscripcion y\nMensualidad y\nEntradas`);
    }
    if(parseInt(precios.mensu) > 0 && parseInt(entradas) > 0){
      setDescripcion(`Mensualidad y\nEntradas`);
    }
    if(parseInt(precios.insc) > 0 && parseInt(entradas) > 0){
      setDescripcion(`Inscripcion y\nEntradas`);
    }

    //validar descripcion
    let valorInscripcion = 0;
    let valorMensualidad = 0;
    switch (descripcion) {
        case `Inscripcion y\nMensualidad`:
            valorInscripcion = precios ? parseInt(precios.insc  === '' ? 0 : parseInt(precios.insc)) : 0;
            valorMensualidad = precios ? parseInt(precios.mensu === '' ? 0 : parseInt(precios.mensu))  : 0;
            break;
        case 'Inscripcion':
            valorInscripcion = precios ? parseInt(precios.insc === '' ? 0 : parseInt(precios.insc)) : 0
            valorMensualidad = 0;
            break;
        case 'Mensualidad':
            valorInscripcion = 0;
            valorMensualidad = precios ? parseInt(precios.mensu === '' ? 0 : parseInt(precios.mensu)) : 0;
            break;
        default:
            break;
    }

    // Definir datos de la tabla
    const tabla = [
      ['Saldo', 'Abonó', 'Entradas', 'Inscripción', 'Mensualidad'],
      [`$${parseInt(afiliado.acum).toLocaleString()}`, `$${bono ? parseInt(bono).toLocaleString() : 0}`, `$${parseInt(entradas !== '' ? entradas : 0).toLocaleString()}`, `$${valorInscripcion.toLocaleString()}`, `$${valorMensualidad.toLocaleString()}`],
    ];

    // Configurar la posición de la tabla
    const startX = 18;
    const startY = 60;
    const cellWidth = 35;
    const cellHeight = 10;

    doc.setFont('arial', '', '');
    doc.setFontSize(11);
    // Dibujar los bordes de la tabla y las celdas
    for (let i = 0; i < tabla.length; i++) {
      for (let j = 0; j < 5; j++) {
          if (i === 0) {
              doc.setDrawColor(0); // Líneas de encabezado más gruesas
              doc.setFillColor(186, 233, 255); // Fondo de encabezado
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight, 'DF');
          } else {
              doc.setDrawColor(0); // Líneas de celdas
              doc.rect(startX + j * cellWidth, startY + i * cellHeight, cellWidth, cellHeight);
          }
      }
    }

    // Dibujar el contenido de la tabla
    doc.setFont('arial', '', '');
    for (let i = 0; i < tabla.length; i++) {
        for (let j = 0; j < 5; j++) {
            doc.text(tabla[i][j], startX + j * cellWidth + 2, startY + i * cellHeight + cellHeight / 2);
        }
    }

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`Monto pagado:`, 20, 90, { align: 'left' });

    doc.setFont('arial', '', '');
    doc.setFontSize(15);
    doc.text(`COP $${(parseInt(entradas !== '' ? entradas : 0) + valorInscripcion + valorMensualidad + parseInt(bono ? bono : 0)).toLocaleString()}`, 190, 90, { align: 'right' });
    doc.line(20, 85, 190, 85);
    doc.line(20, 92, 190, 92);

    //observaciones una pequeña descripcion
    doc.setFont('arial', 'italic', 'bold');
    doc.setFontSize(15);
    doc.text(`Observación`, doc.internal.pageSize.width / 2, 100, { align: 'center' });

    doc.setFont('arial', 'italic', '');
    doc.setFontSize(11);
    doc.text(`${personalizado ? personalizado : afiliado.observ ? afiliado.observ : '----'}`, doc.internal.pageSize.width / 2, 105, { align: 'center' });

   //descripcion de nota importante
   doc.setFont('arial', 'italic', 'bold');
   doc.setFontSize(8);
   doc.text(`NOTA IMPORTANTE`, doc.internal.pageSize.width / 2, 112, { align: 'center' });

   doc.setFont('arial', 'italic', '');
   doc.setFontSize(7);
   doc.text(`Si al 30 de cada mes no se ha regularizado el pago de las\nmensualidades y entradas del deportista, se procederá a\ndesvincularlo del club. Se recuerda que el pago debe realizarse\nde manera puntual entre el 1 y el 5 de cada mes. Si para el día 6\nno se ha realizado el pago, el deportista no podrá ingresar a clases.\nAgradecemos tu comprensión y cooperación.`, doc.internal.pageSize.width / 2, 115, { align: 'center' });


    //firma presidente
    // Agregar el logo de la empresa como marca de agua
    const imgFirma = new Image();
    imgFirma.src = require('../img/img-firma-presidente.png');
    const widthFirma = 50;
    const heightFirma = 25;
    doc.addImage(imgFirma, 'png', 15, 100, widthFirma, heightFirma);
    doc.line(20, 125, 76, 125);
    doc.setFont('arial', 'italic', '');
    doc.setFontSize(14);
    doc.text(`Willman Barbosa Velasquez`, 20, 129, { align: 'left' });
    doc.setFontSize(12);
    doc.text(`Representante Legal`, 20, 133, { align: 'left' });
    
    //firma cliente
    //doc.line(130, 240, 190, 240);

    // Agregar el qr
    const imgQr = new Image();
    imgQr.src = require('../img/img-qr.png');
    const widthQr = 40;
    const heightQr = 40;
    doc.addImage(imgQr, 'png', 145, 95, widthQr, heightQr);

    //parte de abajo del pdf
    doc.setFontSize(8);
    doc.setFont('arial', 'italic');
    doc.text(`www.orcas-valle.com`, 20, 143)
    doc.text(`esnorcas@hotmail.com`, 160, 143)
    doc.text(`ORCAS VALLE`, 84, 143)
    doc.text(`+57 313-739-4026`, 106, 143)

    doc.setProperties({
      title: "Factura ORCAS VALLE"
    });

    setAfiliadoPago(afiliado);
    // Mostrar el PDF en una nueva ventana del navegador
    const generatedPdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(generatedPdfBlob);
    setPdfUrl(pdfUrl);
    
  }