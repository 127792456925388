import React, { useState, useEffect } from 'react';
import { registrarPlanillaRelevos, traerNadadoresRelevos, traerPlanillaRelevos } from '../controllers/configuracionFirebase';
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import Swal from 'sweetalert2'
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const Relevos = ({
  usuario,
  idTorneoParticipando,
  torneoEspecifico,
  pruebasCompletas,
  setNadadoresCarriles,
  planilla}) => {

  const [ relevos, setRelevos ] = useState([]);
  const [ elegirRelevos, setElegirRelevos ] = useState(-1);
  const [ deportistaClub, setDeportistaClub ] = useState('');
  const [ clubes, setClubes ] = useState([]);
  const [ cantCarriles, setCantCarriles ] = useState([]);
  const [ deportistas, setDeportistas ] = useState([]);
  const [ listaDeportistas, setListaDeportistas ] = useState([]);
  const [ inputsDeportistas, setInputsDeportistas ] = useState([{
    name: '',
    code: ''
  }]);

  function anadirInputDeportista(){
    const auxDeportista = [...inputsDeportistas];
    const inputsNuevo = {
      name: '',
      code: ''
    };

    auxDeportista.push(inputsNuevo);
    setInputsDeportistas(auxDeportista);
  }

  function quitarInputDeportista(){
    const auxDeportista = [...inputsDeportistas];
    auxDeportista.splice(auxDeportista.length - 1, 1);
    setInputsDeportistas(auxDeportista);
  }

  function agregarDatosAlInputs(valor, index){
    const auxDeportista = [...inputsDeportistas];
    auxDeportista[index] = valor;
    setInputsDeportistas(auxDeportista);
  }

  function sacarDeportistas(){
    setNadadoresCarriles(planilla);
  }

  function onSubmit(e){
    e.preventDefault();
    const serie = parseInt(e.target.serie.value - 1);
    const carril = parseInt(e.target.carril.value);
    const club = e.target.club.value;
    if(inputsDeportistas.length !== 0 && inputsDeportistas[0].name !== ''){
      let deportista1 = {};
      const identificacionEquipo = [];
      const nombresEquipo = [];
      const edades = [];
      inputsDeportistas.forEach((deport)=>{
        const deportista = deportistas.filter((depor) => depor.nombreclub === deportistaClub)[deport.code]
        deportista1 = deportista;
        identificacionEquipo.push(deportista.identificacion);
        nombresEquipo.push(deportista.nombre);
        edades.push(deportista.edad);
      })
      traerPlanillaRelevos(idTorneoParticipando, e.target.relevo.value).then((planilla)=>{
        if(planilla){
          if(!validarRelevos(identificacionEquipo)){
            if(planilla.planilla.length !== 0 && planilla.planilla[serie]){
              if(planilla.planilla[serie].nadadores[carril - 1].nombre.length !== 0){
                planilla.planilla[serie].nadadores[carril - 1].nombre = [club];
                planilla.planilla[serie].nadadores[carril - 1].nombreclub = [club];
                planilla.planilla[serie].nadadores[carril - 1].equipo = nombresEquipo;
                planilla.planilla[serie].nadadores[carril - 1].edad = [parseInt(deportista1.edad)];
                planilla.planilla[serie].nadadores[carril - 1].edades = edades;
                planilla.planilla[serie].nadadores[carril - 1].club = [deportista1.club];
                const alerta = Swal.mixin({
                  toast: true,
                  showCancelButton: true
                })
                alerta.fire({
                  html: `<div class="w-100 position-relative">
                  <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
                  <p style="z-index: 10; font-family: Poppins" class="mb-0">el carril ${carril} ya esta ocupado. </br> ¿Desea reemplazar?</p>
                  </div>`,
                  confirmButtonText: 'Reemplazar',
                  confirmButtonColor: 'rgb(18, 53, 248)',
                  padding: 8,
                  cancelButtonText: 'Cancelar',
                }).then((respuesta) =>{
                  if(respuesta.isConfirmed){
                    registrarPlanillaRelevos(idTorneoParticipando, e.target.relevo.value, planilla);
                    sacarDeportistas();
                  }
                })
              }else{
                planilla.planilla[serie].nadadores[carril - 1].nombre = [club];
                planilla.planilla[serie].nadadores[carril - 1].nombreclub = [club];
                planilla.planilla[serie].nadadores[carril - 1].equipo = nombresEquipo;
                planilla.planilla[serie].nadadores[carril - 1].edad = [parseInt(deportista1.edad)];
                planilla.planilla[serie].nadadores[carril - 1].edades = edades;
                planilla.planilla[serie].nadadores[carril - 1].club = [deportista1.club];
                
                registrarPlanillaRelevos(idTorneoParticipando, e.target.relevo.value, planilla);
                sacarDeportistas();
              }
            }else{
              const auxSerie = {
                Serie: serie + 1,
                nadadores: []
              }
    
              for (let i = 0; i < parseInt(torneoEspecifico.carril); i++) {
                const auxCarriles = {
                  carril: i + 1,
                  club: [],
                  edad: [],
                  edades: [],
                  identificacion: [0],
                  nombre: [],
                  nombreclub: [],
                  equipo: [],
                  fechaNacimiento: [],
                  genero: [],
                  codePais: [],
                  records: []
                }
    
                auxSerie.nadadores.push(auxCarriles);
              }
              planilla.planilla[serie] = auxSerie;
              planilla.planilla[serie].nadadores[carril - 1].nombre = [club];
              planilla.planilla[serie].nadadores[carril - 1].nombreclub = [club];
              planilla.planilla[serie].nadadores[carril - 1].equipo = nombresEquipo;
              planilla.planilla[serie].nadadores[carril - 1].edad = [parseInt(deportista1.edad)];
              planilla.planilla[serie].nadadores[carril - 1].edades = edades;
              planilla.planilla[serie].nadadores[carril - 1].club = [deportista1.club];
              registrarPlanillaRelevos(idTorneoParticipando, e.target.relevo.value, planilla);
              sacarDeportistas();
            }
          }else{
            toast.error('Se encontro un deportista repetido. Por favor verifica.');
          }
        }else{
          toast.error('El relevo no estan registrado en la planilla.');
        }
      })
    }else{
      toast.error('Debe haber 4 deportistas en el relevo.');
    }
  }

  function validarRelevos(arr) {
    const conjunto = new Set();
    for (const elemento of arr) {
      // Verificar si el elemento no es vacío
      if (elemento !== "") {
        if (conjunto.has(elemento)) {
          // Elemento duplicado encontrado
          return true;
        }
        conjunto.add(elemento);
      }
    }
    // No se encontraron elementos duplicados
    return false;
  }

  function todasLasEdades(){
    const auxDeportistasNombres = [];
    const relevoElegido = document.getElementById('relevo').value;
    if(relevoElegido !== ''){
      if(deportistas.filter((depor) => depor.nombreclub === deportistaClub).length !== 0){
        deportistas.filter((depor) => depor.nombreclub === deportistaClub).forEach((deport, indexDeport)=>{
          const objDeport = {
            name: `${deport.nombre}/${deport.edad} años/${deport.nombreclub}`,
            code: indexDeport
          };
          auxDeportistasNombres.push(objDeport);
        })
      }
      setListaDeportistas(auxDeportistasNombres);
    }else{
      toast.error('Elige el relevo.');
    }
  }

  function edadesPrueba(){
    const auxDeportistasNombres = [];
    const relevoElegido = document.getElementById('relevo').value;
    if(relevoElegido !== ''){
      const aux = [];
      pruebasCompletas.forEach((prue)=>{
        const auxPrueba = prue.filter((auxPrue)=>{return auxPrue.id === parseInt(relevoElegido)});
        if(auxPrueba.length !== 0){
          aux.push(auxPrueba[0]);
        }
      })
      if(aux.length !== 0){
        if(deportistas.filter((depor) => depor.nombreclub === deportistaClub && (parseInt(aux[0].edadInicio) <= depor.edad && parseInt(aux[0].edadFin) >= depor.edad)).length !== 0){
          deportistas.filter((depor) => depor.nombreclub === deportistaClub && (parseInt(aux[0].edadInicio) <= depor.edad && parseInt(aux[0].edadFin) >= depor.edad)).forEach((deport, indexDeport)=>{
            const objDeport = {
              name: `${deport.nombre}/${deport.edad} años/${deport.nombreclub}`,
              code: indexDeport
            };
            auxDeportistasNombres.push(objDeport);
          })
        }
      }
      setListaDeportistas(auxDeportistasNombres);
    }else{
      toast.error('Elige el relevo.');
    }
  }

  useEffect(() => {
    function sacarPruebasRelevos(){
      const relevosEncontrados = [];
      pruebasCompletas.forEach((pruebas)=>{
        const auxRelevos = [];
        pruebas.forEach((prue)=>{
          const relevo = prue.prueba.prueba.match(/Relevo/g);
          if(relevo){
            auxRelevos.push(prue);
          }
        })
        relevosEncontrados.push(auxRelevos);
      })
      setRelevos(relevosEncontrados);
    }

    function carriles(){
      const auxCarriles = [];
      for (let i = 0; i < parseInt(torneoEspecifico.carril); i++) {
        auxCarriles.push(i + 1);
      }
      setCantCarriles(auxCarriles);
    }

    carriles();
    traerNadadoresRelevos(idTorneoParticipando, setDeportistas);
    sacarPruebasRelevos();
  }, [usuario, setDeportistas, setCantCarriles, idTorneoParticipando])

  useEffect(()=>{
    function sacarClubes(){
      const auxClubes = new Set();
      deportistas.forEach((depor)=>{
        auxClubes.add(depor.nombreclub);
      })
      setClubes(Array.from(auxClubes));
    }

    sacarClubes();
  }, [deportistas])

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='position-relative'>
          <motion.div
            className="card m-1 pt-1 col"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}
          >
            <form onSubmit={onSubmit} className='d-flex align-items-center flex-wrap'>
              <div className="card-body w-100">
                <div className='d-flex flex-wrap justify-content-between mb-1'>
                  <div className="form-floating w-100" style={{ maxWidth: '350px'}}>
                    <select name="jornada" id='jornada' className='form-select' required onClick={(ev) => setElegirRelevos(parseInt(ev.target.value))}>
                      <option value={-1}>Elige la jornada</option>
                      {relevos.length !== 0 ?(
                        relevos.map((jornada, indexJornada)=>{
                          return <option key={indexJornada} value={indexJornada}>{`Jornada ${indexJornada + 1}`}</option>
                        })
                        ):(
                        <option value="">No hay jornadas</option>
                      )}
                    </select>
                    <label htmlFor="jornada">{`${relevos.length} Jornadas Disponibles`}</label>
                  </div>
                  <div className="form-floating w-100" style={{ maxWidth: '350px'}}>
                    <select name="club" id='club' className='form-select' required onClick={(ev) => setDeportistaClub(ev.target.value)}>
                      <option value="">Elige el club</option>
                      {clubes.length !== 0 ?(
                        clubes.map((club, indexClub)=>{
                          return <option key={indexClub} value={club}>{club}</option>
                        })
                        ):(
                        <option value="">No hay clubes</option>
                      )}
                    </select>
                    <label htmlFor="club">{`${clubes.length} Clubs Disponibles`}</label>
                  </div>
                </div>
                <div className='d-flex flex-wrap justify-content-between mb-1'>
                  <div className="form-floating w-100" style={{ maxWidth: '100px'}}>
                    <select name="carril" id='carril' className='form-select' required>
                      <option value="">0</option>
                      {cantCarriles.length !== 0 ?(
                        cantCarriles.map((carril, indexCarril)=>{
                          return <option key={indexCarril} value={carril}>{carril}</option>
                        })
                        ):(
                        <option value="">No hay carril</option>
                      )}
                    </select>
                    <label htmlFor="carril">{`Carriles`}</label>
                  </div>
                  <div className="form-floating w-100" style={{ maxWidth: '100px'}}>
                    <input type="number" name='serie' id='serie' className='form-control' required/>
                    <label htmlFor="serie">{`Serie`}</label>
                  </div>
                  <div className="form-floating w-100" style={{ maxWidth: '495px'}}>
                    <select name="relevo" id='relevo' className='form-select' required>
                      <option value="">Elige el relevo</option>
                      {elegirRelevos !== -1 ?(
                        relevos[elegirRelevos].map((rel, indexRel)=>{
                          return <option key={indexRel} value={rel.id}>{`${rel.id} | ${rel.prueba.prueba} - ${rel.categoria}`}</option>
                        })
                        ):(
                        <option value="">No hay relevos</option>
                      )}
                    </select>
                    <label htmlFor="relevo">{`${elegirRelevos !== -1 ? relevos[elegirRelevos].length : 0} Relevos Disponibles`}</label>
                  </div>
                </div>
                {elegirRelevos !== -1 && deportistaClub !== '' ? (
                  <div className='d-flex flex-wrap justify-content-center mb-1'>
                    <Button type="button" icon='pi pi-users' className='btn btn-secondary me-1' label='Todas Edades' onClick={() => todasLasEdades()} style={{ width: '200px'}}/>
                    <Button type="button" icon='pi pi-user' className='btn btn-info me-1' label='Edades Prueba' onClick={() => edadesPrueba()} style={{ width: '200px'}}/>
                  </div>
                ):(
                  <></>
                )}
                {listaDeportistas.length !== 0 ? (
                  <>
                    <div className='d-flex flex-wrap justify-content-center mb-1'>
                      <Button type="button" icon='pi pi-plus' className='btn btn-primary me-1' label='Deportista' onClick={() => anadirInputDeportista()} style={{ width: '150px'}}/>
                      <Button type="button" icon='pi pi-minus' className='btn btn-danger' label='Deportista' onClick={() => quitarInputDeportista()} style={{ width: '150px'}}/>
                    </div>
                    {inputsDeportistas.length !== 0 ?(
                      inputsDeportistas.map((inp, indexInp)=>{
                        return <div key={indexInp} className="p-inputgroup flex-1 mb-1">
                        <Dropdown value={inp} onChange={(e) => agregarDatosAlInputs(e.value, indexInp)} filter options={listaDeportistas} placeholder={listaDeportistas.length + ' Deportistas Disponibles'} optionLabel='name'/>
                      </div>
                      })
                    ):(
                      <></>
                    )}
                  </>
                ):(
                  <></>
                )}
                <div className='d-flex justify-content-center'>
                  <Button
                    type="submit"
                    icon='pi pi-check'
                    label="Inscribir Relevo"
                    className="btn btn-success"
                    style={{ width: '200px'}}
                  />
                </div>
              </div>
            </form>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
}

export default Relevos;