import { useState } from "react";
import "../styles/Torneo.css";
import GradientText from "./GradientText";

function Marquee({
  items = [],
  pauseOnHover = false,
  size = "clamp(8rem, 100%, 25rem)",
  duration = "60s",
  textColor = "#ffffff",
  bgColor = "#060606",
  bgAccentColor = "#111111"
}) {
  const [isPaused, setIsPaused] = useState(false);

  const wrapperClass = ["logoWall-wrapper"]
    .filter(Boolean)
    .join(" ");

  const marqueeClass = ["marquee", isPaused && "paused"]
    .filter(Boolean)
    .join(" ");

  return (
    <article
      className={wrapperClass}
      style={{
        "--size": size,
        "--duration": duration,
        "--color-text": textColor,
        "--color-bg": bgColor,
        "--color-bg-accent": bgAccentColor
      }}
    >
      <div
        className={marqueeClass}
        onMouseEnter={() => pauseOnHover && setIsPaused(true)}
        onMouseLeave={() => pauseOnHover && setIsPaused(false)}
      >
        <div className="marquee__group">
          {items.map((item, idx) => (
            <span key={idx}>
              <GradientText
                colors={["#ff0000", "#006fff", "#ff0000", "#006fff", "#ff0000"]}
                animationSpeed={8}
                showBorder={false}
                className="custom-class"
              >
                {item}
              </GradientText>
            </span>
          ))}
        </div>
        <div className="marquee__group" aria-hidden="true">
          {items.map((item, idx) => (
            <span key={`dup1-${idx}`}>
              <GradientText
                colors={["#ff0000", "#006fff", "#ff0000", "#006fff", "#ff0000"]}
                animationSpeed={8}
                showBorder={false}
                className="custom-class"
              >
                {item}
              </GradientText>
            </span>
          ))}
        </div>
      </div>
    </article>
  );
}

export default Marquee;