import * as ExcelJS from 'exceljs';

function arreglarTiempoEstandar(time){
  const auxTiemposSeparados = time.split(':');
  const minutos = parseInt(auxTiemposSeparados[0]);
  const segundos = parseInt(auxTiemposSeparados[1]);
  const milisegundos = parseInt(auxTiemposSeparados[2]);
  if(minutos !== 0){
    return minutos + ':' + segundos + '.' + milisegundos;
  }else{
    return segundos + '.' + milisegundos;
  }
}

export const exportToExcel = async (afiliados) => {
  const workbook = new ExcelJS.Workbook();
  const fecha = new Date();
  const worksheetAfi = workbook.addWorksheet('Afiliados');
  const worksheet = workbook.addWorksheet('Tabla de contenido');
  const worksheetHist = workbook.addWorksheet('Historial de Pagos');

  // encabezado
  worksheet.addRow(['Nombre', 'Identificación', 'Fecha Nacimiento', 'Edad', 'Sexo', 'Inscripción', 'Mensualidad', 'Activo', 'Vence', 'Debe', 'Saldo', 'Observaciones']);
  worksheetAfi.addRow([`Afiliados año ${fecha.getFullYear()}`])
  worksheetAfi.mergeCells('A1:F1'); 
  worksheetAfi.addRow(['Nombre', 'Identificación', 'Fecha Nacimiento', 'Edad', 'Sexo', 'Contacto']);

  //Filas con los datos
  afiliados.forEach((row)=>{
    const auxPagoInsc = row.pagoInsc ? row.pagoInsc : 'No';
    const auxPagoMensu = row.pagoMensu ? row.pagoMensu : 'No';
    const auxActivo = row.afiliado ? 'Afiliado' : 'Retirado';
    const auxSexo = row.genero === 'Masculino' ? 'M' : 'F';
    worksheet.addRow([(row.nombre).toUpperCase(), parseInt(row.ti), row.fechaNacimiento, parseInt(row.edad), auxSexo, auxPagoInsc, auxPagoMensu, auxActivo, row.fechaPago, `$${row.totalAPagar}`, `$${row.acum}`, row.observ]);
    worksheetAfi.addRow([(row.nombre).toUpperCase(), parseInt(row.ti), row.fechaNacimiento, parseInt(row.edad), auxSexo, row.cel]);
  })

  //centrar contenidos
  worksheet.eachRow((row)=>{
    row.eachCell((cell)=>{
      cell.alignment = {vertical: 'middle', horizontal: 'center'}
    })
  })

  worksheetAfi.eachRow((row)=>{
    row.eachCell((cell)=>{
      cell.alignment = {vertical: 'middle', horizontal: 'center'}
    })
  })

  //color encabezado
  worksheet.getRow(1).eachCell((cell) => {
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' } // gris
    };
    cell.font = {
      size: '14',
      bold: '500',
    }
  });

  worksheetAfi.getRow(1).eachCell((cell) => {
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' } // gris
    };
    cell.font = {
      size: '18',
      bold: '500',
    }
  });

  worksheetAfi.getRow(2).eachCell((cell) => {
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' } // gris
    };
    cell.font = {
      size: '14',
      bold: '500',
    }
  });

  //ancho columnas
  const columnA = worksheet.getColumn('A');
  columnA.width = 32;
  const columnB = worksheet.getColumn('B');
  columnB.width = 17;
  const columnC = worksheet.getColumn('C');
  columnC.width = 22;
  const columnD = worksheet.getColumn('D');
  columnD.width = 7;
  const columnE = worksheet.getColumn('E');
  columnE.width = 7;
  const columnF = worksheet.getColumn('F');
  columnF.width = 14;
  const columnG = worksheet.getColumn('G');
  columnG.width = 16;
  const columnH = worksheet.getColumn('H');
  const columnI = worksheet.getColumn('I');
  columnI.width = 13;
  const columnJ = worksheet.getColumn('J');
  columnJ.width = 13;
  const columnK = worksheet.getColumn('K');
  columnK.width = 13;
  const columnL = worksheet.getColumn('L');
  columnL.width = 36;

  const columnAfiA = worksheetAfi.getColumn('A');
  columnAfiA.width = 32;
  const columnAfiB = worksheetAfi.getColumn('B');
  columnAfiB.width = 17;
  const columnAfiC = worksheetAfi.getColumn('C');
  columnAfiC.width = 22;
  const columnAfiD = worksheetAfi.getColumn('D');
  columnAfiD.width = 7;
  const columnAfiE = worksheetAfi.getColumn('E');
  columnAfiE.width = 7;
  const columnAfiF = worksheetAfi.getColumn('F');
  columnAfiF.width = 16;

  // Establecer bordes para la tabla
  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  worksheetAfi.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  //Si o No o Afiliado o Retirado cambio de colores
  columnF.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    // Ignora la primera fila que contiene encabezados
    if (rowNumber > 1) {
        // Verifica el contenido y establece el color de fondo en consecuencia
        if (cell.text === 'Si') {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' } // Amarillo
            };
        } else {
            // Si es 'no' o cualquier otro valor, no establece un color de fondo
            cell.fill = null;
        }
    }
  });

  columnG.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    // Ignora la primera fila que contiene encabezados
    if (rowNumber > 1) {
        // Verifica el contenido y establece el color de fondo en consecuencia
        if (cell.text === 'Si') {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' } // Amarillo
            };
        } else {
            // Si es 'no' o cualquier otro valor, no establece un color de fondo
            cell.fill = null;
        }
    }
  });

  columnH.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    // Ignora la primera fila que contiene encabezados
    if (rowNumber > 1) {
        // Verifica el contenido y establece el color de fondo en consecuencia
        if (cell.text === 'Afiliado') {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '00B050' } // Verde
            };
        } else {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF0000' } // Rojo
          };
        }
    }
  });

  // ---------------- Historial de pagos ----------------------

  // encabezado
  worksheetHist.addRow([`Historial de pagos año ${fecha.getFullYear()}`])
  worksheetHist.mergeCells('A1:M1');
  worksheetHist.addRow(['Nombre', 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']);

  //Filas con los datos
  afiliados.forEach((row)=>{
    worksheetHist.addRow([(row.nombre).toUpperCase(), row.hist[0].title, row.hist[1].title, row.hist[2].title, row.hist[3].title, row.hist[4].title, row.hist[5].title, row.hist[6].title, row.hist[7].title, row.hist[8].title, row.hist[9].title, row.hist[10].title, row.hist[11].title]);
  })

  //centrar contenidos
  worksheetHist.eachRow((row)=>{
    row.eachCell((cell)=>{
      cell.alignment = {vertical: 'middle', horizontal: 'center'}
    })
  })

  //color encabezado
  worksheetHist.getRow(1).eachCell((cell) => {
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' } // gris
    };
    cell.font = {
      size: '18',
      bold: '500',
    }
  });

  worksheetHist.getRow(2).eachCell((cell) => {
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' } // gris
    };
    cell.font = {
      size: '14',
      bold: '500',
    }
  });

  //ancho columnas
  const columnHistA = worksheetHist.getColumn('A');
  columnHistA.width = 32;
  const columnHistB = worksheetHist.getColumn('B');
  columnHistB.width = 17;
  const columnHistC = worksheetHist.getColumn('C');
  columnHistC.width = 17;
  const columnHistD = worksheetHist.getColumn('D');
  columnHistD.width = 17;
  const columnHistE = worksheetHist.getColumn('E');
  columnHistE.width = 17;
  const columnHistF = worksheetHist.getColumn('F');
  columnHistF.width = 17;
  const columnHistG = worksheetHist.getColumn('G');
  columnHistG.width = 17;
  const columnHistH = worksheetHist.getColumn('H');
  columnHistH.width = 17;
  const columnHistI = worksheetHist.getColumn('I');
  columnHistI.width = 17;
  const columnHistJ = worksheetHist.getColumn('J');
  columnHistJ.width = 17;
  const columnHistK = worksheetHist.getColumn('K');
  columnHistK.width = 17;
  const columnHistL = worksheetHist.getColumn('L');
  columnHistL.width = 17;
  const columnHistM = worksheetHist.getColumn('M');
  columnHistM.width = 17;

  // Establecer bordes para la tabla
  worksheetHist.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  colorRojoVerdePagado(columnHistB);
  colorRojoVerdePagado(columnHistC);
  colorRojoVerdePagado(columnHistD);
  colorRojoVerdePagado(columnHistE);
  colorRojoVerdePagado(columnHistF);
  colorRojoVerdePagado(columnHistG);
  colorRojoVerdePagado(columnHistH);
  colorRojoVerdePagado(columnHistI);
  colorRojoVerdePagado(columnHistJ);
  colorRojoVerdePagado(columnHistK);
  colorRojoVerdePagado(columnHistL);
  colorRojoVerdePagado(columnHistM);

  // ---------------- Asistencias ----------------------
  const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  for (let i = 0; i <= 11; i++) {
    const worksheetAsistencias = workbook.addWorksheet(`Asistencias ${meses[i]}`);
    
    // encabezado
    worksheetAsistencias.addRow([`Asistencias del mes de ${meses[i]} año ${fecha.getFullYear()}`])
    worksheetAsistencias.mergeCells('A1:AG1');
    worksheetAsistencias.addRow(['Nombre', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', 
      '26', '27', '28', '29', '30', '31', 'Total']);
  
    //Filas con los datos
    afiliados.forEach((row)=>{
      const auxDias = [];
      let contadorAsistencias = 0;
      for (let j = 1; j <= 31; j++) {
        const auxFecha = (i + 1) + '/' + j + '/' + fecha.getFullYear();
        if(row.asistencias){
          const auxBuscarFecha = row.asistencias.filter((asi)=>{return asi === auxFecha});
          if(auxBuscarFecha.length !== 0){
            auxDias.push('✓');
            contadorAsistencias++;
          }else{
            auxDias.push(' ');
          }
        }else{
          auxDias.push(' ');
        }
      }
      auxDias.push(contadorAsistencias);
      worksheetAsistencias.addRow([(row.nombre).toUpperCase(), ...auxDias]);
    })
  
    //centrar contenidos
    worksheetAsistencias.eachRow((row)=>{
      row.eachCell((cell)=>{
        cell.alignment = {vertical: 'middle', horizontal: 'center'}
      })
    })
  
    //color encabezado
    worksheetAsistencias.getRow(1).eachCell((cell) => {
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' } // gris
      };
      cell.font = {
        size: '18',
        bold: '500',
      }
    });
  
    worksheetAsistencias.getRow(2).eachCell((cell) => {
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D9D9D9' } // gris
      };
      cell.font = {
        size: '14',
        bold: '500',
      }
    });
  
    //ancho columnas
    const columnAsisA = worksheetAsistencias.getColumn('A');
    columnAsisA.width = 32;
    const columnAsisB = worksheetAsistencias.getColumn('B');
    columnAsisB.width = 6;
    const columnAsisC = worksheetAsistencias.getColumn('C');
    columnAsisC.width = 6;
    const columnAsisD = worksheetAsistencias.getColumn('D');
    columnAsisD.width = 6;
    const columnAsisE = worksheetAsistencias.getColumn('E');
    columnAsisE.width = 6;
    const columnAsisF = worksheetAsistencias.getColumn('F');
    columnAsisF.width = 6;
    const columnAsisG = worksheetAsistencias.getColumn('G');
    columnAsisG.width = 6;
    const columnAsisH = worksheetAsistencias.getColumn('H');
    columnAsisH.width = 6;
    const columnAsisI = worksheetAsistencias.getColumn('I');
    columnAsisI.width = 6;
    const columnAsisJ = worksheetAsistencias.getColumn('J');
    columnAsisJ.width = 6;
    const columnAsisK = worksheetAsistencias.getColumn('K');
    columnAsisK.width = 6;
    const columnAsisL = worksheetAsistencias.getColumn('L');
    columnAsisL.width = 6;
    const columnAsisM = worksheetAsistencias.getColumn('M');
    columnAsisM.width = 6;
    const columnAsisN = worksheetAsistencias.getColumn('N');
    columnAsisN.width = 6;
    const columnAsisO = worksheetAsistencias.getColumn('O');
    columnAsisO.width = 6;
    const columnAsisP = worksheetAsistencias.getColumn('P');
    columnAsisP.width = 6;
    const columnAsisQ = worksheetAsistencias.getColumn('Q');
    columnAsisQ.width = 6;
    const columnAsisR = worksheetAsistencias.getColumn('R');
    columnAsisR.width = 6;
    const columnAsisS = worksheetAsistencias.getColumn('S');
    columnAsisS.width = 6;
    const columnAsisT = worksheetAsistencias.getColumn('T');
    columnAsisT.width = 6;
    const columnAsisU = worksheetAsistencias.getColumn('U');
    columnAsisU.width = 6;
    const columnAsisV = worksheetAsistencias.getColumn('V');
    columnAsisV.width = 6;
    const columnAsisW = worksheetAsistencias.getColumn('W');
    columnAsisW.width = 6;
    const columnAsisX = worksheetAsistencias.getColumn('X');
    columnAsisX.width = 6;
    const columnAsisY = worksheetAsistencias.getColumn('Y');
    columnAsisY.width = 6;
    const columnAsisZ = worksheetAsistencias.getColumn('Z');
    columnAsisZ.width = 6;
    const columnAsisAA = worksheetAsistencias.getColumn('AA');
    columnAsisAA.width = 6;
    const columnAsisAB = worksheetAsistencias.getColumn('AB');
    columnAsisAB.width = 6;
    const columnAsisAC = worksheetAsistencias.getColumn('AC');
    columnAsisAC.width = 6;
    const columnAsisAD = worksheetAsistencias.getColumn('AD');
    columnAsisAD.width = 6;
    const columnAsisAE = worksheetAsistencias.getColumn('AE');
    columnAsisAE.width = 6;
    const columnAsisAF = worksheetAsistencias.getColumn('AF');
    columnAsisAF.width = 6;
    const columnAsisAG = worksheetAsistencias.getColumn('AG');
    columnAsisAG.width = 8;

    colorVerdeAsistio(columnAsisB);
    colorVerdeAsistio(columnAsisC);
    colorVerdeAsistio(columnAsisD);
    colorVerdeAsistio(columnAsisE);
    colorVerdeAsistio(columnAsisF);
    colorVerdeAsistio(columnAsisG);
    colorVerdeAsistio(columnAsisH);
    colorVerdeAsistio(columnAsisI);
    colorVerdeAsistio(columnAsisJ);
    colorVerdeAsistio(columnAsisK);
    colorVerdeAsistio(columnAsisL);
    colorVerdeAsistio(columnAsisM);
    colorVerdeAsistio(columnAsisN);
    colorVerdeAsistio(columnAsisO);
    colorVerdeAsistio(columnAsisP);
    colorVerdeAsistio(columnAsisQ);
    colorVerdeAsistio(columnAsisR);
    colorVerdeAsistio(columnAsisS);
    colorVerdeAsistio(columnAsisT);
    colorVerdeAsistio(columnAsisU);
    colorVerdeAsistio(columnAsisV);
    colorVerdeAsistio(columnAsisW);
    colorVerdeAsistio(columnAsisX);
    colorVerdeAsistio(columnAsisY);
    colorVerdeAsistio(columnAsisZ);
    colorVerdeAsistio(columnAsisAA);
    colorVerdeAsistio(columnAsisAB);
    colorVerdeAsistio(columnAsisAC);
    colorVerdeAsistio(columnAsisAD);
    colorVerdeAsistio(columnAsisAE);
    colorVerdeAsistio(columnAsisAF);
  
    // Establecer bordes para la tabla
    worksheetAsistencias.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });
  }

  // Crea un Blob para descargar el archivo
  const blob = await workbook.xlsx.writeBuffer();
  const blobData = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Crea un enlace y simula un clic para descargar el archivo
  const url = URL.createObjectURL(blobData);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Afiliados, tabla de contenido, historial de pagos y asistencias.xlsx';
  a.click();
};

function colorVerdeAsistio(columnAsis){
  return columnAsis.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    // Ignora la primera fila que contiene encabezados
    if (rowNumber > 2) {
      // Verifica el contenido y establece el color de fondo en consecuencia
      switch (cell.text) {
        case '✓':
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00B050' } // Verde
          };
          cell.font = {
            bold: true
          };
          break;
        default:
          break;
      }
    }
  });
}

function colorRojoVerdePagado(columnHist){
  return columnHist.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
    // Ignora la primera fila que contiene encabezados
    if (rowNumber > 2) {
      // Verifica el contenido y establece el color de fondo en consecuencia
      switch (cell.text) {
        case 'Pagado':
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00B050' } // Verde
          };
          break;
        case 'Debiendo':
          // Si es 'no' o cualquier otro valor, no establece un color de fondo
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF0000' } // Rojo
          };
          break;
        case 'Ninguno':
          // Si es 'no' o cualquier otro valor, no establece un color de fondo
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '000000' } // Negro
          };
          break;
      
        default:
          break;
      }
    }
  });
}

export async function exportTiempos(tiempos){
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`Tiempos Resultados`);
  let cantNadadores = 1;
  tiempos.forEach((tiemp)=>{
    worksheet.addRow([`${tiemp.id + 1}`, `${tiemp.categoria}`]);
    worksheet.addRow([`${tiemp.prueba.prueba}`]);
    worksheet.addRow(['Pto', 'Ln', 'Sr', 'Deportista', 'Edad' , 'Insc.', 'Comp.', 'Equipo']);
    worksheet.mergeCells(`B${cantNadadores}:H${cantNadadores}`);
    worksheet.mergeCells(`A${cantNadadores + 1}:H${cantNadadores + 1}`);

    worksheet.getRow(cantNadadores).eachCell((cell) => {
      cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd8f1ff' }
      };
      cell.font = {
        size: '14',
      }
    });

    let contadorLugar = 1;
    tiemp.nadadores.forEach((nad)=>{
      worksheet.addRow([contadorLugar, nad.carril, nad.serie, nad.nombre, nad.edad, arreglarTiempoEstandar(nad.record), arreglarTiempoEstandar(nad.tiempo), nad.nombreclub]);
      contadorLugar ++;
      cantNadadores ++;
    })
    worksheet.addRow([]);
    cantNadadores += 4;
  })

  //ancho columnas
  const columnA = worksheet.getColumn('A');
  columnA.width = 5;
  const columnB = worksheet.getColumn('B');
  columnB.width = 4;
  const columnC = worksheet.getColumn('C');
  columnC.width = 4;
  const columnD = worksheet.getColumn('D');
  columnD.width = 32;
  const columnE = worksheet.getColumn('E');
  columnE.width = 6;
  const columnF = worksheet.getColumn('F');
  columnF.width = 8;
  const columnG = worksheet.getColumn('G');
  columnG.width = 8;
  const columnH = worksheet.getColumn('H');
  columnH.width = 32;

  //centrar contenidos
  worksheet.eachRow((row)=>{
    row.eachCell((cell)=>{
      cell.alignment = {vertical: 'middle', horizontal: 'center'}
    })
  })

  // Establecer bordes para la tabla
  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
  });

  // Crea un Blob para descargar el archivo
  const blob = await workbook.xlsx.writeBuffer();
  const blobData = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Crea un enlace y simula un clic para descargar el archivo
  const url = URL.createObjectURL(blobData);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Tiempos.xlsx';
  a.click();
}

export async function planillaToExcel(pruebas, nadadores){
  const workbook = new ExcelJS.Workbook();
  pruebas.forEach((jornada, indexJor)=>{
    const worksheet = workbook.addWorksheet(`Jornada ${indexJor + 1}`);
    let cantNadadores = 1;
    let sumaNadadoresSerie = 0;
    jornada.forEach((prue)=>{
      sumaNadadoresSerie = 0;
      worksheet.addRow([`${prue.id}`, `${prue.categoria}`]);
      worksheet.addRow([`${prue.prueba.prueba}`]);
      worksheet.addRow(['Lugar', 'Carril', 'Deportista', 'Club', 'Tiempo']);
      if(nadadores[prue.id - 1].length !== 0){
        let contadorLugar = 1;
        nadadores[prue.id - 1].forEach((serie)=>{
          serie.nadadores.forEach((nadador)=>{
            worksheet.addRow([contadorLugar, nadador.carril, nadador.nombre[0], nadador.club[0], '']);
            contadorLugar ++;
          })
          sumaNadadoresSerie += serie.nadadores.length;
        })
      }
      worksheet.addRow([]);
      const cell = worksheet.getCell(`B${cantNadadores}`);
      if (!cell.isMerged) {
        //color encabezado
        worksheet.getRow(cantNadadores).eachCell((cell) => {
          cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D9D9D9' } // gris
          };
          cell.font = {
            size: '14',
          }
        });

        worksheet.getRow(cantNadadores + 1).eachCell((cell) => {
          cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D9D9D9' } // gris
          };
          cell.font = {
            size: '14',
          }
        });

        //combinar celdas
        worksheet.mergeCells(`B${cantNadadores}:E${cantNadadores}`);
        worksheet.mergeCells(`A${cantNadadores + 1}:E${cantNadadores + 1}`);
        cantNadadores += 4 + sumaNadadoresSerie;
      }
    })

    //centrar contenidos
    worksheet.eachRow((row)=>{
      row.eachCell((cell)=>{
        cell.alignment = {vertical: 'middle', horizontal: 'center'}
      })
    })

    // Establecer bordes para la tabla
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });

    // Establecer el formato de celda para que se interprete como tiempo
    const columnD = worksheet.getColumn('E');
    columnD.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      cell.numFmt = 'mm:ss.000';
    });
    const columnB = worksheet.getColumn('C');
    columnB.width = 32;
  })


  // Crea un Blob para descargar el archivo
  const blob = await workbook.xlsx.writeBuffer();
  const blobData = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  // Crea un enlace y simula un clic para descargar el archivo
  const url = URL.createObjectURL(blobData);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Programación.xlsx';
  a.click();
}

export function tiemposToHy3(resultados, torneo){
  // Datos de ejemplo
  const meetInfo = { name: torneo.titulo, id: "0000000000" };
  const idClubs = {};
  const clubs = new Set();

  resultados.forEach((result)=>{
    result.nadadores.forEach((nad)=>{
      if(!idClubs[nad.nombreclub] && !nad.equipo){
        clubs.add({nombre: nad.nombreclub, codePais: nad.codePais ? nad.codePais : 'COL'});
        idClubs[nad.nombreclub] = nad.nombreclub;
      }
    })
  })

  let hy3Content = "";

  // Línea A: Información del meet
  hy3Content += `A ${meetInfo.name} ${meetInfo.id}\n`;
  
  // Línea B: Información del equipo
  Array.from(clubs).forEach((club, indexClub)=>{
    hy3Content += `B ${club.nombre}\t${club.codePais}\t00${indexClub + 1}\n`;
  })

  // Línea C: Información de los atletas
  resultados.forEach((result)=>{
    result.nadadores.forEach((nad)=>{
      if(!nad.equipo){
        hy3Content += `C ${nad.nombre}\t${nad.genero[0]}\t${nad.fechaNacimiento.replace(/-/g, '')}\tCOL\n`;
      }
    })
  })

  // Línea D: Información de eventos
  resultados.forEach((result)=>{
    result.nadadores.forEach((nad)=>{
      if(!nad.equipo){
        hy3Content += `D ${result.prueba.prueba}\t${nad.serie}\t${nad.carril}\t${nad.tiempo}\t${nad.codePais ? nad.codePais : 'COL'}\n`;
      }
    })
  })

  // Crear y descargar automáticamente el archivo
  const blob = new Blob([hy3Content], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${torneo.titulo}.hy3`;
  link.click();
}