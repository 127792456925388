import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import { traerClubesAutorizados, actualizarClubesAutorizados, eliminarClubesAutorizados, registrarNombreAutorizado, buscarAutorizaciones, buscarNombresAutorizados } from "../controllers/configuracionFirebase";
import { toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tooltip as Mensaje } from "react-tooltip";
import { Message } from "primereact/message";
import { datosAdmin, departamentosColombia } from "../controllers/administrador";

const Autorizaciones = ({ usuario }) => {

  const [ autorizado, setAutorizado ] = useState([]);
  const [ nombresAutorizados, setNombresAutorizados ] = useState([]);
  const [ palabraEcontrada, setPalabraEncontrada ] = useState(false);
  const clubEscuelaNatacion = [
    "CLUB",
    "ESCUELA",
    "NATACIÓN",
    "NATACION",
  ];

  function clickEliminarClub(index, nombre){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 33px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">Esta seguro de eliminar a ${nombre.nombre}, tenga en cuenta que, </br> ya no podra iniciar sesión.</p>
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 8,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        traerClubesAutorizados().then((clubes) =>{
          const auxClubesAutorizados = [...clubes]
          auxClubesAutorizados.splice(index, 1)
          eliminarClubesAutorizados(auxClubesAutorizados, nombre);
        })
      }
    })
  }

  function clicklike(index, nombre){
    traerClubesAutorizados().then((clubes) =>{
      const auxClubesAutorizados = [...clubes]
      auxClubesAutorizados.splice(index, 1)
      const actualizar = {
        nombre: nombresAutorizados[index].nombre,
        autorizado: false,
        abreviatura: nombresAutorizados[index].abreviatura,
        departamento: nombresAutorizados[index].departamento,
        codePais: nombresAutorizados[index].codePais
      }
      const datosActualizados = [...auxClubesAutorizados, actualizar]
      actualizarClubesAutorizados(datosActualizados)
      toast.success(nombre.nombre + ', ya no esta autorizado para ingresar',{
        icon: '😢'
      });
    })
  }

  function clickdislike(index, nombre){
    traerClubesAutorizados().then((clubes) =>{
      const auxClubesAutorizados = [...clubes]
      auxClubesAutorizados.splice(index, 1)
      const actualizar = {
        nombre: nombresAutorizados[index].nombre,
        autorizado: true,
        abreviatura: nombresAutorizados[index].abreviatura,
        departamento: nombresAutorizados[index].departamento,
        codePais: nombresAutorizados[index].codePais
      }
      const datosActualizados = [...auxClubesAutorizados, actualizar]
      actualizarClubesAutorizados(datosActualizados)
      toast.success(nombre.nombre + ', ahora esta autorizado para ingresar');
    })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const nombre = e.target.nombreClub.value;
    const auxAbre = e.target.abreviaturaClub.value;
    const abreviatura = auxAbre.toUpperCase();
    const departamento = e.target.departamento.value;
    const sesion = e.target.iniciosesion.value;
    const codePais = departamentosColombia.filter((dep)=>dep.dep === departamento)[0]?.codePais;
    
    const nombreAutorizado = nombre.toUpperCase();
    const Registro = {
      nombre: nombreAutorizado,
      autorizado: true,
      abreviatura: abreviatura,
      departamento,
      codePais
    }

    const nombreEncontrado = nombresAutorizados.some(club => club.nombre === nombre);
    const abreviaturaEncontrado = nombresAutorizados.some(club => club.abreviatura === abreviatura);

    if(nombreEncontrado){
      toast.error('El equipo ' + nombre + ', ya esta registrado en las autorizaciones.');
    }

    if(abreviaturaEncontrado){
      toast.error('Ya existe un equipo con la abreviatura ' + abreviatura + '.');
    }

    if(!nombreEncontrado && !abreviaturaEncontrado){
      const auxArr = [...nombresAutorizados, Registro]
      registrarNombreAutorizado(auxArr, nombreAutorizado, sesion);
      e.target.reset();
    }
  };

  const acciones = (club, indexClub) => {
    return (
      <div>
        <Button disabled={club.nombre === datosAdmin.nombre || club.nombre === 'PROWEBSPORTS'} icon={autorizado[indexClub] ? 'pi pi-thumbs-up-fill' : 'pi pi-thumbs-down-fill'} text severity={autorizado[indexClub] ? 'primary' : 'danger'} tooltip={autorizado[indexClub] ? 'Desautorizar' : 'Autorizar'} tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
        onClick={autorizado[indexClub] ? () => clicklike(indexClub, club) : () => clickdislike(indexClub, club)}/>
        <Button disabled={club.nombre === datosAdmin.nombre || club.nombre === 'PROWEBSPORTS'} className='me-1' text icon="pi pi-times" severity="danger" tooltip='Eliminar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
        onClick={() => clickEliminarClub(indexClub, club)}/>
      </div>
    );
  };

  const content = (
    <div className="d-flex align-items-center">
        <img alt="logo" src={require("../img/img-logo-swimmingt.webp")} width="50" />
        <div className="ms-2 text-danger">Por favor. Ingresar solo el nombre del equipo, no incluir "CLUB" o "ESCUELA" o "NATACIÓN"</div>
    </div>
  );
  
  function buscarPalabra(e){
    const auxPalabra = e.target.value;
    const arregloFrase = auxPalabra.toUpperCase().split(" ");
    let encontrada = false;

    clubEscuelaNatacion.forEach((error)=>{
      arregloFrase.forEach((palab)=>{
        if(error === palab){
          encontrada = true;
        }
      })
    })
    setPalabraEncontrada(encontrada);
  }

  useEffect(() => {
    buscarNombresAutorizados(setNombresAutorizados);
    buscarAutorizaciones(setAutorizado);
  }, [setNombresAutorizados, setAutorizado])

  return (
    <>
      <motion.div className="bg-light"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <form onSubmit={onSubmit}>
          <div className="alert alert-success alert-dismissible fade show" role="alert">
            <h3>AUTORIZAR EQUIPO</h3>
          </div>
          {usuario.nombreclub === 'PROWEBSPORTS' ?(
            <>
              <div className="d-flex flex-wrap justify-content-center">
                <div className="form-floating me-1">
                  <input type="text" className="form-control mb-1" name="nombreClub"
                  id="nombreClub" placeholder="Nombre del club" maxLength={24} required onChange={buscarPalabra}/>
                  <label htmlFor="nombreClub">Nombre equipo</label>
                </div>
                <div className="form-floating me-1">
                  <select name="departamento" id="depart" className="form-select mb-1" required>
                    <option value=''>Elige el departamento/pais</option>
                    {departamentosColombia.map((depar, indexDepart)=>{
                      return <option value={depar.dep} key={indexDepart}>{depar.dep}</option>
                    })}
                  </select>
                  <label htmlFor="depart">Departamento/Pais</label>
                </div>
                <div className="form-floating me-1">
                  <input type="text" className="form-control" name="abreviaturaClub"
                  id="abreviaturaClub" placeholder="Abreviatura, 4 caracteres" maxLength={4} minLength={4}
                  required style={{width: '170px'}}/>
                  <label htmlFor="abreviaturaClub">Abreviatura equipo</label>
                </div>
                <div className="form-floating">
                  <select name="iniciosesion" id="iniciosesion" className="form-select mb-1" required>
                    <option value=''>Elige</option>
                    <option value='si'>Incluir</option>
                    <option value='no'>No Incluir</option>
                  </select>
                  <label htmlFor="iniciosesion">Inicio Sesión</label>
                </div>
              </div>
              <Mensaje
                anchorId={`nombreClub`}
                place="bottom"
                content={'Max. 24 caracteres.'}
                className="bg-primary shadow"
                style={{ zIndex: 1}}
              />
              {palabraEcontrada ? (
                <div className="card">
                  <Message
                    style={{
                        border: 'solid #696cff',
                        borderWidth: '0 0 0 6px',
                        color: '#696cff'
                    }}
                    className="border-danger justify-content-center"
                    severity="error"
                    content={content}
                />
                </div>
              ):(
                <></>
              )}
              <Button type="submit" icon='pi pi-thumbs-up-fill' label="Autorizar" className="btn btn-success mt-1" disabled={palabraEcontrada}></Button>
            </>
          ):(
            <></>
          )}
        </form>
        <hr />
        <DataTable value={nombresAutorizados} size='small' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} stripedRows footer={'Total autorizados: ' + nombresAutorizados.length} emptyMessage="No hay autorizados aún."
        tableStyle={{ minWidth: '40rem', margin: 'auto'}}>
          <Column header="#" body={(_, index)=>{return index.rowIndex + 1}} style={{ width: '30px'}}></Column>
          <Column field="nombre" header="Equipo" style={{ width: '110px'}}></Column>
          <Column body={(dep)=>{return <img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={departamentosColombia.filter((depBan)=>{return depBan.dep === dep.departamento})[0].bandera}/>}} style={{ width: '80px'}}></Column>
          <Column field="departamento" header="Departamento" style={{ width: '110px'}}></Column>
          <Column field="abreviatura" header="Abreviatura" style={{ width: '80px'}}></Column>
          <Column field="codePais" header="Cod. Pais" style={{ width: '80px'}}></Column>
          <Column body={(club, indexClub) => acciones(club, indexClub.rowIndex)} style={{ width: '100px'}}></Column>
        </DataTable>
      </motion.div>
    </>
  );
}

export default Autorizaciones;