import React, { useState, useEffect, useRef } from "react";
import { buscarNombresListado, registrarUsuario, sendEmail } from '../controllers/configuracionFirebase';
import { toast } from "react-hot-toast";
import { Button } from "primereact/button";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Dialog } from 'primereact/dialog';
import { datosAdmin, departamentosColombia } from "../controllers/administrador";
import Swal from "sweetalert2";
import { anioAutor } from "../controllers/tiempoLimiteInscripcion";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Avatar } from "primereact/avatar";

const Registrar = ({ setLogin, loginv }) => {

  const [ nombres, setNombres ] = useState([]);
  const [ selectedClub, setSelectedClub ] = useState(null);
  const [ contrasena, setContrasena ] = useState('');
  const [ validarContrasena, setValidarContrasena ] = useState('');
  const [ dialogPedir, setDialogPedir ] = useState(false);
  const [ dialogTerminosyCondiciones, setDialogTerminosyCondiciones ] = useState(false);

  const form = useRef();

  /* la funcion clickRegistrarse nos ayudara a pasar al otro formulario que es Login. */
  function clickRegistrarse() {
    setLogin(true);
  }

  function mostrarMensajeIntegracionNombreClub(){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0"><b>¿No encuentras el nombre</br>de tu equipo en la lista?</b><br/>
      Solicítalo y lo añadiremos<br/>rápidamente.
      </div>`,
      position: 'top-start',
      confirmButtonText: 'Solicitar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        setDialogPedir(true);
      }
    })
  }

  function pedirIntegracionNombreClub(e){
    e.preventDefault();

    const plataforma = e.target.plataforma.value;
    const nombreclub = e.target.nombre.value;
    const departamento = e.target.departamento.value;
    const contacto = e.target.contacto.value;
    const emails = [
      'prowebsports@gmail.com',
      'ebarbosa2499@gmail.com',
      'esnorcas@hotmail.com',
    ];
    const asunto = `Solicitud de ${nombreclub}`
    const text = `
    <h1 style="line-height: 1.2">¡Hola! Ingresar a la lista de autorizaciones en la plataforma de ${plataforma} al siguiente equipo.</h1>
    <p style="margin: 0"><b>Plataforma: </b><a href="${datosAdmin.plataforma}" target="_blank">${plataforma}</a></p>
    <p style="margin: 0"><b>Nombre club:</b> ${nombreclub}</p>
    <p style="margin: 0"><b>Departamento:</b> ${departamento}</p>
    <p style="margin: 0"><b>Contacto:</b> ${contacto}</p>
    `;

    const toastId = toast.loading('Enviando solicitud...');
    sendEmail(asunto, text, emails).then(() =>{
      toast.dismiss(toastId);
      toast.success('Solicitud enviada correctamente.');
    }).catch((err)=>{
      toast.dismiss(toastId);
      toast.success('¡UPS! Ocurrio un error. Intentalo de nuevo.');
    });
  }

  /* la funcion onSubmit traera todos los datos que se han ingresado al registrarse,
  y asi mismo validara si las credenciales son correctas o no. 
  hay que tener en cuenta que esto solo dejara registrar a los usuarios que tengan correos como:*/
  function onSubmit(e) {
    e.preventDefault();

    const nombreclub = selectedClub;
    const correo = e.target.email.value;
    const password = contrasena
    const passwordValidar = validarContrasena
    if(nombreclub){
      if(password === passwordValidar){
        registrarUsuario(correo, password, nombreclub);
        setSelectedClub(null);
        setContrasena('');
        setValidarContrasena('');
        e.target.reset();
      }else{
        toast.error('Las contraseñas no coinciden, por favor verifica.');
      }
    }else{
      toast.error('Para registrarse, es necesario elegir el nombre del club.');
    }
  }

  useEffect(() => {
    buscarNombresListado(setNombres);
  }, [setNombres])

  return (
    <motion.div className="p-3 shadow m-auto" style={{ maxWidth: "400px", background: 'linear-gradient(110deg, rgba(250, 248, 156, 0.5), rgba(144, 222, 253, 0.5), rgba(253, 144, 144, 0.5))', borderRadius: '10px' }}
    initial={{ scale: 0, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 1, type: 'spring' }}  
    >
      <form
        className={
          loginv
            ? "column g-3 was-validated form-registrar-inativo form-prime"
            : "column g-3 was-validated form-prime position-relative"
        }
        onSubmit={onSubmit}
      >
        <Avatar className="position-absolute bottom-0 start-0" image={require('../img/pwsmascota-izquierda.png')} size="large"/>
        <Avatar className="position-absolute top-0 end-0" image={require('../img/img_logo.png')} size="large"/>
        <div className="alert mb-0" role="alert">
          <h1 style={{fontFamily: 'Abril Fatface'}}>
            ¡BIENVENIDO!
          </h1>
          <p className="m-0" style={{fontFamily: 'Alkatra', textAlign: 'justify', lineHeight: 1.3}}>
            Regístrate en la plataforma de <strong>{datosAdmin.nombre}</strong> y vive una experiencia profesional y eficiente.
          </p>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon submit">
              <i className="pi pi-users"></i>
          </span>
          <Dropdown value={selectedClub} onChange={(e) => setSelectedClub(e.value)} filter options={nombres} placeholder={nombres.length + ' Equipos Disponibles'} emptyMessage='No hay equipos aún.' onClick={() => mostrarMensajeIntegracionNombreClub()}/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon submit">
              <i className="pi pi-at"></i>
          </span>
          <InputText type="email" name="email" placeholder="E-mail@example.com" required/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon submit">
              <i className={contrasena !== '' && contrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
          </span>
          <Password value={contrasena} onChange={(e) => setContrasena(e.target.value)} placeholder="Contraseña" toggleMask minLength={6} required
          promptLabel="Min. 6 Caracteres"
          weakLabel="Débil"
          mediumLabel="Media"
          strongLabel="Fuerte"/>
        </div>
        <div className="p-inputgroup flex-1 mb-1">
          <span className="p-inputgroup-addon submit">
              <i className={contrasena === validarContrasena && validarContrasena !== '' && validarContrasena.length >= 6 ? 'pi pi-lock' : 'pi pi-unlock'}></i>
          </span>
          <Password value={validarContrasena} onChange={(e) => setValidarContrasena(e.target.value)} placeholder="Validar Contraseña" toggleMask minLength={6} required
          feedback={false}/>
        </div>
        <p className="mb-1 m-0 text-start" style={{fontSize: '14px', textAlign: 'start', lineHeight: 1.2}}>
          Al registrarse o iniciar sesión, aceptas nuestros 
          <button type="button" className="link text-primary p-0" onClick={() => setDialogTerminosyCondiciones(true)}>
            términos y condiciones
          </button>
        </p>
        <div className="col-auto">
          <Button type="submit" icon='pi pi-user' label="CREAR UNA CUENTA" className="btn btn-success w-100 mb-2"></Button>
        </div>
        <div className="col-auto">
          <p>
              ¿Ya tienes una cuenta?{" "}
            <button type="button" className="link text-primary" onClick={clickRegistrarse}>
              Iniciar sesión
            </button>
          </p>
        </div>
        <div className="prowebsports">
          <Link to='https://www.prowebsports.com.co/inicio' target="_blank" className="mt-3" style={{fontSize: '12px', textDecoration: 'none'}}>© 2023 - {anioAutor()} prowebsports</Link>
        </div>
      </form>
      <Dialog visible={dialogPedir} maximizable style={{ width: '500px' }} breakpoints={{ '641px': '100%' }} header={`Solicitud para añadir el nombre del equipo`} modal className="p-fluid" onHide={() => setDialogPedir(false)}>
        <div className="field">
          <form onSubmit={pedirIntegracionNombreClub} ref={form}>
            <div className="form-floating">
              <input type="text" className='form-control mt-1' value={datosAdmin.nombre} name='plataforma' id='plataforma' required/>
              <label htmlFor='plataforma'>Plataforma</label>
            </div>
            <div className="form-floating">
              <input type="text" className='form-control mt-1' name='nombre' id='nombre' required/>
              <label htmlFor='nombre'>Nombre Equipo</label>
            </div>
            <div className="form-floating">
              <select name='departamento' id="departamento" className='form-select mt-1' required>
                {departamentosColombia.map((depar, indexDepar)=>{
                  return <option key={indexDepar} value={depar.dep}>{depar.dep}</option>
                })}
              </select>
              <label htmlFor="departamento">Departamento/Pais</label>
            </div>
            <div className="form-floating">
              <input type="number" className='form-control mt-1 mb-1' name='contacto' id='contacto' required/>
              <label htmlFor='contacto'>Numero de WhatsApp</label>
            </div>
            <Button type="submit" icon='pi pi-info-circle' label="Enviar solicitud" className="btn btn-primary w-100 mb-1"></Button>
          </form>
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <b>IMPORTANTE:</b><br />Esta solicitud debe ser realizada exclusivamente por el entrenador, quien será contactado posteriormente para proporcionar más información.
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </Dialog>
      <Dialog visible={dialogTerminosyCondiciones} maximizable style={{ width: '500px', height: '500px' }} breakpoints={{ '641px': '100%' }} header={`Términos y Condiciones 2025`} modal className="p-fluid" onHide={() => setDialogTerminosyCondiciones(false)}>
        <div className="field">
          <p>
          <b>1. Aceptación de los Términos</b><br/>
          Al registrarse o acceder a cualquiera de las plataformas de <b>PROWEBSPORTS</b>, el usuario acepta cumplir con estos Términos y Condiciones. <br/>
          Las plataformas están disponibles tanto para invitados como para administradores, quienes pueden hacer uso de todas sus funciones conforme a lo estipulado en este documento.<br/><br/>

          <b>2. Uso de las Plataformas</b> <br/>
          • Los equipos invitados que deseen participar en un evento de <b>{datosAdmin.nombre}</b> deberán registrarse en la plataforma para inscribir a sus deportistas.<br/>
          • Los usuarios que solo deseen ver los resultados durante el evento no necesitarán registrarse ni iniciar sesión, ya que podrán visualizar los resultados desde el exterior de la plataforma.<br/>
          • Los usuarios registrados tienen acceso a funciones avanzadas, como <b>Crear torneos</b>, <b>Ranking</b>, <b>Inscripciones</b>, <b>Control de equipos</b>, <b>Registro de tiempos</b>, <b>Resultados</b>, <b>Puntuaciones</b>, <b>Estadísticas de categorías</b>, <b>Programación</b>, <b>Rifas</b>, <b>Autorizaciones</b> entre otras funcionalidades.<br/><br/>
          
          <b>3. Registro de Usuarios</b> <br/>
          Al registrarse, el usuario se compromete a proporcionar información veraz y completa, incluyendo: <br/>
          <b>Nombre del equipo</b><br/>
          <b>Correo electrónico</b><br/>
          <b>Contraseña</b><br/>
          Posteriormente, deberá registrar información de los deportistas, como:<br/>
          <b>Nombre completo</b><br/>
          <b>Número de identificación</b><br/>
          <b>Edad</b><br/>
          <b>Fecha de nacimiento</b><br/>
          <b>Género</b><br/><br/>
          
          <b>4. Seguridad de las Contraseñas</b><br/>
          Las contraseñas registradas en <b>PROWEBSPORTS</b> están encriptadas y no es posible su recuperación en caso de olvido. Por razones de seguridad, ningún miembro del equipo de <b>PROWEBSPORTS</b> tiene acceso a las contraseñas ni proporcionará información relacionada con ellas. Si el usuario olvida su contraseña, deberá utilizar la opción de recuperación para establecer una nueva.<br/><br/>
          
          <b>5. Privacidad de la Información</b> <br/>
          <b>PROWEBSPORTS</b> no comparte la información de los usuarios con terceros. La información recopilada se utiliza exclusivamente para gestionar eventos y enviar resultados a los correos registrados.<br/><br/>

          <b>6. Propiedad Intelectual</b> <br/>
          Todo el contenido, diseño, código y funcionalidades de las plataformas de <b>PROWEBSPORTS</b> son propiedad exclusiva de la plataforma. Queda prohibida la reproducción, distribución o uso indebido de cualquier parte de las plataformas sin autorización expresa.<br/><br/>

          <b>7. Responsabilidad</b> <br/>
          <b>PROWEBSPORTS</b> garantiza la estabilidad y precisión de sus plataformas. En caso de inconvenientes o dudas, el equipo de soporte estará disponible para resolver consultas.<br/><br/>

          <b>8. Modificaciones a los Términos y Condiciones</b> <br/>
          <b>PROWEBSPORTS</b> se reserva el derecho de modificar estos Términos y Condiciones en cualquier momento. Las modificaciones se publicarán en las plataformas de <b>PROWEBSPORTS</b> y entrarán en vigor inmediatamente después de su publicación. Se recomienda a los usuarios revisar regularmente los Términos y Condiciones para estar al tanto de cualquier cambio.
          </p>
        </div>
      </Dialog>
    </motion.div>
  );
};

export default Registrar;