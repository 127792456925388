import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { consolidado } from '../controllers/configuracionFirebase';
import { Avatar } from 'primereact/avatar';

const Consolidado = ({ idTorneoParticipando }) => {

  const [ clubes, setClubes ] = useState([]);
  const [ consolidadoGeneral, setConsolidadoGeneral ] = useState({});
  const [ varones, setVarones ] = useState([]);
  const [ damas, setDamas ] = useState([]);
  const [ mixto, setMixto ] = useState([]);
  const [ generos, setGeneros ] = useState([]);
  const [ totales, setTotales ] = useState([]);

  const parseEdad = (edad) => {
    if (edad.includes('-')) {
      // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
      return parseInt(edad.split('-')[1], 10);
    }
    if (edad.includes('y Mas')) {
      // Si la edad tiene un rango (ej: '20-80'), tomamos el valor menor
      return parseInt(edad.split('-')[0], 10);
    }
    // Si es un número único, lo convertimos a entero
    return parseInt(edad, 10);
  };

  useEffect(() => {
    consolidado(idTorneoParticipando, setClubes);
  }, [setClubes, idTorneoParticipando])

  useEffect(() => {
    function sacarPruebas() {
      const auxTotalesClub = [];
      const auxVarones = [];
      const auxDamas = [];
      const auxGeneros = new Set();
      const auxDamasEdades = new Set();
      const auxVaronesEdades = new Set();
      const auxClubes = {}

      clubes.sort((a,b)=>{
        if(a.nombreclub < b.nombreclub){
          return -1;
        }
        if(a.nombreclub > b.nombreclub){
          return 1;
        }
        return 0;
      });

      clubes.forEach((club)=>{
        if(!auxClubes[club.nombreclub]){
          auxClubes[club.nombreclub] = {};
        }
        
        club.nadadores.forEach((nad) => {
          auxGeneros.add(nad.genero);
          
          if (!auxClubes[nad.nombreclub]) {
            auxClubes[nad.nombreclub] = {};
          }
        
          if (!auxClubes[nad.nombreclub][nad.genero]) {
            auxClubes[nad.nombreclub][nad.genero] = {};
          }
        
          if (!auxClubes[nad.nombreclub][nad.genero][nad.categoria]) {
            auxClubes[nad.nombreclub][nad.genero][nad.categoria] = {
              categoria: nad.categoria,
              cantidad: 0
            };
          }
        
          // Incrementar el contador de cantidad para la categoría correspondiente
          auxClubes[nad.nombreclub][nad.genero][nad.categoria].cantidad++;
        });

        club.nadadores.forEach((nad)=>{
          if(nad.genero === 'Femenino'){
            if(!auxDamasEdades.has(nad.categoria)){
              auxDamasEdades.add(nad.categoria);
              auxDamas.push({edad: nad.categoria});
            }
          }else{
            if(!auxVaronesEdades.has(nad.categoria)){
              auxVaronesEdades.add(nad.categoria);
              auxVarones.push({edad: nad.categoria});
            }
          }
        })
      })

      // Objeto para almacenar los totales por género y categoría
      const totalGeneros = {
        Femenino: {},
        Masculino: {}
      };

      // Recorremos el objeto auxClubes
      Object.entries(auxClubes).forEach(([club, generos]) => {
        Object.entries(generos).forEach(([genero, categorias]) => {
          // Si el género no existe en el objeto de totales, lo inicializamos
          if (!totalGeneros[genero]) {
            totalGeneros[genero] = {};
          }
        
          Object.entries(categorias).forEach(([categoria, data]) => {
            // Si la categoría dentro del género no existe, la inicializamos en 0
            if (!totalGeneros[genero][categoria]) {
              totalGeneros[genero][categoria] = 0;
            }
          
            // Sumamos la cantidad de nadadores de esa categoría para el género correspondiente
            totalGeneros[genero][categoria] += data.cantidad;
          });
        });
      });

      Object.entries(totalGeneros).forEach(([generos, generosValue])=>{
        Object.entries(generosValue).sort((a, b) => parseEdad(a) - parseEdad(b)).forEach(([categoria, categoriaValue])=>{
          auxTotalesClub.push(categoriaValue);
        })
      });

      // Obtener las claves y ordenarlas
      const sortedKeys = Object.keys(auxClubes).sort();

      // Crear un nuevo objeto con las claves ordenadas
      const sortedObj = {};
      sortedKeys.forEach(key => {
        sortedObj[key] = auxClubes[key];
      });
      setTotales(auxTotalesClub);
      setDamas(auxDamas.sort((a, b) => parseEdad(a.edad) - parseEdad(b.edad)));
      setVarones(auxVarones.sort((a, b) => parseEdad(a.edad) - parseEdad(b.edad)));
      setGeneros(Array.from(auxGeneros).sort());
      setConsolidadoGeneral(sortedObj);
    }
    sacarPruebas();
  }, [clubes])

  return (
    <>
      <motion.div className="pb-3"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{duration: 1}}
      >
        <div className='bg-light p-2 shadow table-responsive-xl'>
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th className="titulo-consolidado" colSpan={16}>ESTADISTICAS DE CATEGORIAS</th>
              </tr>
              <tr>
                <th colSpan={2}></th>
                {generos.map((gene, indexGene)=>{
                  return <th key={indexGene} colSpan={gene === 'Femenino' ? damas.length : gene === 'Masculino' ? varones.length : mixto.length}>{gene === 'Femenino' ? 'Damas' : gene === 'Masculino' ? 'Varones' : 'Mixto'}</th>
                })}
                <th></th>
              </tr>
              <tr>
                <th className="clubes" colSpan={2}>Clubes</th>
                {
                  damas.map(function(cate, indexCate){
                    return <th className="categorias-damas" key={indexCate}>{cate.edad}</th>
                  })
                }
                {
                  varones.map(function(cate, indexCate){
                    return <th className="categorias-varones" key={indexCate}>{cate.edad}</th>
                  })
                }
                {
                  mixto.map(function(cate, indexCate){
                    return <th className="categorias-varones" key={indexCate}>{cate.edad}</th>
                  })
                }
                <th className="total">Total</th>
              </tr>
            </thead>
            <tbody>
              {
                clubes.map(function(club, indexClub){
                  return club.nadadores.length !== 0 ?(
                  <tr key={indexClub}>
                    <td><Avatar className="flex align-items-center justify-content-center mr-2 shadow" image={club.photo} label={club.photo !== null && club.photo.endsWith(".pdf") ? '' : club.nombreclub[0] + club.nombreclub[1]}/></td>
                    <td className="nombreclub">{club.nombreclub}</td>
                    {damas.length !== 0 ? (
                      damas.map((dam, indexDam)=>{
                        return <td key={indexDam}>{consolidadoGeneral[club.nombreclub] ? consolidadoGeneral[club.nombreclub]['Femenino'] ? consolidadoGeneral[club.nombreclub]['Femenino'][dam.edad] ? consolidadoGeneral[club.nombreclub]['Femenino'][dam.edad].cantidad : 0 : 0 : 0}</td>
                      })
                    ):(
                      <></>
                    )}
                    {varones.length !== 0 ? (
                      varones.map((varo, indexVaro)=>{
                        return <td key={indexVaro}>{consolidadoGeneral[club.nombreclub] ? consolidadoGeneral[club.nombreclub]['Masculino'] ? consolidadoGeneral[club.nombreclub]['Masculino'][varo.edad] ? consolidadoGeneral[club.nombreclub]['Masculino'][varo.edad].cantidad : 0 : 0 : 0}</td>
                      })
                    ):(
                      <></>
                    )}
                    <td className="rojo">{club.nadadores.length}</td>
                  </tr>
                  ):(
                    <></>
                  )
                })
              }
              {
                <tr>
                  <th className="total total-clubes" colSpan={2}>Total</th>
                  {totales.length !== 0 ?(
                    totales.map((tol, indexTol)=>{
                      return <td key={indexTol}>{tol}</td>
                    })
                  ):(
                    <></>
                  )}
                  <th className="total-1">{totales.reduce((acumulado, valor) => acumulado + valor, 0)}</th>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </motion.div>
    </>
  );
}

export default Consolidado;