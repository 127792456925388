import jsPDF from 'jspdf';
import toast from 'react-hot-toast';
import { datosAdmin } from './administrador';
import "../fonts/ABeeZee-Regular-normal";
import "../fonts/ABeeZee-Italic-italic";
import "../fonts/DMSerifText-Italic-italic";
import "../fonts/DMSerifText-Regular-normal";
import { diccionarioEsAEn } from './diccionario';

const clubPropietario = datosAdmin.nombre;

export function arreglarTiempoEstandar(time){
  const auxTiemposSeparados = time.split(':');
  const minutos = parseInt(auxTiemposSeparados[0]);
  const segundos = parseInt(auxTiemposSeparados[1]);
  const milisegundos = parseInt(auxTiemposSeparados[2]);
  if(minutos !== 0){
    return minutos + ':' + (segundos < 10 && segundos !== 0 ? '0' + segundos : segundos) + '.' + (milisegundos < 10 && milisegundos !== 0 ? '0' + milisegundos : milisegundos);
  }else{
    return (segundos < 10 && segundos !== 0 ? '0' + segundos : segundos) + '.' + (milisegundos < 10 && milisegundos !== 0 ? '0' + milisegundos : milisegundos);
  }
}

function acortarNombreClub(nombreCompleto, cantidadCaracteres) {
  // Eliminar espacios al inicio y al final del nombre completo
  nombreCompleto = nombreCompleto.trim();

  // Tomar la cantidad especificada de caracteres
  const nombreAcortado = nombreCompleto.slice(0, cantidadCaracteres);

  return nombreAcortado;
}

function sacarRecordDePrueba(prueba, genero, detallesTorneo, recordsMundial, recordsNacional){
  let record = {};

  if(genero === 'Femenino' || genero === 'Masculino'){
    record.mundial = recordsMundial[genero.toLowerCase()][detallesTorneo.piscina === 'ninguno' ? '25' : detallesTorneo.piscina][prueba]?.records;
    record.nacional = recordsNacional[genero.toLowerCase()][detallesTorneo.piscina === 'ninguno' ? '25' : detallesTorneo.piscina][prueba]?.records; 
  }

  return record;
}

export function pdfPlanilla(planilla, setPdfUrl, detallesTorneo, pruebasCompletas, usuario, llamados, marcarLinea, color, recordsMundial, recordsNacional, idioma) {
  const doc = new jsPDF({
    format: 'letter',
  });
  const logoImg = new Image();
  const qrResultados = new Image();
  const qrResultadosPws = new Image();
  const logoImgPws = new Image();
  const marco = new Image();
  logoImg.src = require('../img/img_logo.png');
  qrResultados.src = require('../img/qr-resultados.png');
  qrResultadosPws.src = require('../img/qr-resultados-pws.png');
  logoImgPws.src = require('../img/img-logo-factura.png');
  marco.src = require('../img/img-marco.png');
  const width = 22;
  const height = 22;
  const columnWidth = doc.internal.pageSize.width / 3;
  let x = 10;
  let y = 30;

  if (planilla.length === 0) {
    doc.setFontSize(11);
    doc.setFont('Times', 'italic');
    doc.text(usuario.nombreclub + '\nLa programación aún no se ha registrado.', doc.internal.pageSize.width / 2, 48, { align: 'center' });
  } else {
    let newPage = false;
    pruebasCompletas.forEach((obj, indexObj) => {
      if (newPage) {
        doc.addPage(); // Agregar una nueva página si es necesario
        newPage = false; // Restablecer la variable
        x = 10; // Restablecer la posición X
        y = 30;
      }
      doc.setTextColor('black');
      doc.setFontSize(15);
      doc.setFont('DMSerifText-Italic', 'italic');
      doc.text(`${doc.getNumberOfPages() < 10 ? '0' + doc.getNumberOfPages() : doc.getNumberOfPages()}`, 178, 25);
      doc.setFont('DMSerifText-Italic', 'italic');
      doc.setFontSize(11);
      doc.addImage(marco, 'png', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
      doc.addImage(logoImg, 'png', 20, 3, width, height);
      doc.text(idioma === 'ingles' ? diccionarioEsAEn['resultados'] : `resultados`, 185, 6.6);
      doc.addImage(qrResultados, 'png', 185, 7, 18, 18);
      doc.text(`${detallesTorneo.titulo !== '' ? detallesTorneo.titulo : ''}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
      doc.setFontSize(8);
      doc.setFont('ABeeZee-Italic', 'italic');
      doc.text(`${detallesTorneo.direccion}\n${detallesTorneo.jornadas[indexObj].fecha}`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
      doc.setFont('DMSerifText-Italic', 'italic');
      doc.setFontSize(10);
      doc.text((idioma === 'ingles' ? diccionarioEsAEn['Jornada'] + ' ' : 'Jornada ') + (indexObj + 1), doc.internal.pageSize.width / 2, 25, { align: 'center' });
      doc.text((idioma === 'ingles' ? diccionarioEsAEn['Calentamiento'] + ' ' : 'Calentamiento: ') + (detallesTorneo.jornadas[indexObj].calentamiento), doc.internal.pageSize.width / 3, 25, { align: 'center' });
      doc.text((idioma === 'ingles' ? diccionarioEsAEn['Inicio Competencia'] + ' ' : 'Inicio Competencia: ') + (detallesTorneo.jornadas[indexObj].inicio), doc.internal.pageSize.width / 1.5, 25, { align: 'center' });
      doc.line(20, 26, 203, 26);
      doc.line(25, 270, 190, 270);
      doc.setFontSize(8);
      doc.setFont('Times', 'italic');
      doc.addImage(qrResultadosPws, 'png', 10, 265, 10, 10);
      doc.text(`www.prowebsports.com.co`, 25, 273)
      doc.text(`PROWEBSPORTS`, 84, 273)
      doc.text(`+57 324-588-4041`, 106, 273)
      doc.text(`pws@prowebsports.com.co`, 159, 273)
      doc.addImage(logoImgPws, 'png', 195, 265, 10, 10);
      obj.forEach((prue, indexPrue)=>{
        let quitarEspacio = true;
        doc.setTextColor('black');
        doc.setFontSize(11);
        doc.setFont('DMSerifText-Italic', 'italic');
        const text = `${prue.id} ${idioma === 'ingles' && diccionarioEsAEn[prue.categoria] ? diccionarioEsAEn[prue.categoria] : prue.categoria}`;
        const textPrueba = `${idioma === 'ingles' && diccionarioEsAEn[prue.prueba.prueba] ? diccionarioEsAEn[prue.prueba.prueba] : prue.prueba.prueba}`;
        doc.setFontSize(7.3);
        doc.setFont('ABeeZee-Regular', 'normal');
        const auxRecords = `RM:${sacarRecordDePrueba(prue.prueba.prueba, prue.genero, detallesTorneo, recordsMundial, recordsNacional).mundial ? sacarRecordDePrueba(prue.prueba.prueba, prue.genero, detallesTorneo, recordsMundial, recordsNacional).mundial : ''} RN:${sacarRecordDePrueba(prue.prueba.prueba, prue.genero, detallesTorneo, recordsMundial, recordsNacional).nacional ? sacarRecordDePrueba(prue.prueba.prueba, prue.genero, detallesTorneo, recordsMundial, recordsNacional).nacional : ''}`;

        if (y >= doc.internal.pageSize.height - 18) {
          // Cambiar a la siguiente columna o página
          x += columnWidth;
          y = 30;
    
          if (x >= columnWidth * 3) {
            doc.addPage();
            doc.setTextColor('black');
            doc.setFontSize(15);
            doc.setFont('DMSerifText-Italic', 'italic');
            doc.text(`${doc.getNumberOfPages() < 10 ? '0' + doc.getNumberOfPages() : doc.getNumberOfPages()}`, 178, 25);
            doc.setFont('DMSerifText-Italic', 'italic');
            doc.setFontSize(11);
            doc.addImage(marco, 'png', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
            doc.addImage(logoImg, 'png', 20, 3, width, height);
            doc.text(idioma === 'ingles' ? diccionarioEsAEn['resultados'] : `resultados`, 185, 6.6);
            doc.addImage(qrResultados, 'png', 185, 7, 18, 18);
            doc.text(`${detallesTorneo.titulo !== '' ? detallesTorneo.titulo : ''}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
            doc.setFontSize(8);
            doc.setFont('ABeeZee-Italic', 'italic');
            doc.text(`${detallesTorneo.direccion}\n${detallesTorneo.jornadas[indexObj].fecha}`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
            doc.setFont('DMSerifText-Italic', 'italic');
            doc.setFontSize(10);
            doc.text((idioma === 'ingles' ? diccionarioEsAEn['Jornada'] + ' ' : 'Jornada ') + (indexObj + 1), doc.internal.pageSize.width / 2, 25, { align: 'center' });
            doc.text((idioma === 'ingles' ? diccionarioEsAEn['Calentamiento'] + ' ' : 'Calentamiento: ') + (detallesTorneo.jornadas[indexObj].calentamiento), doc.internal.pageSize.width / 3, 25, { align: 'center' });
            doc.text((idioma === 'ingles' ? diccionarioEsAEn['Inicio Competencia'] + ' ' : 'Inicio Competencia: ') + (detallesTorneo.jornadas[indexObj].inicio), doc.internal.pageSize.width / 1.5, 25, { align: 'center' });
            doc.line(20, 26, 190, 26);
            doc.line(25, 270, 190, 270);
            doc.setFontSize(8);
            doc.setFont('Times', 'italic');
            doc.addImage(qrResultadosPws, 'png', 10, 265, 10, 10);
            doc.text(`www.prowebsports.com.co`, 25, 273)
            doc.text(`PROWEBSPORTS`, 84, 273)
            doc.text(`+57 324-588-4041`, 106, 273)
            doc.text(`pws@prowebsports.com.co`, 159, 273)
            doc.addImage(logoImgPws, 'png', 195, 265, 10, 10);
            x = 10;
          }

          if(x >= columnWidth * 1 && x <= columnWidth * 2){
            x -= 5;
          }
          if(x >= columnWidth * 2 && x <= columnWidth * 3){
            x -= 4;
          }
        }
  
        doc.setFontSize(11);
        doc.setFont('DMSerifText-Italic', 'italic');
        doc.text(x + 25, y, text, {align: 'center'});
        doc.text(x + 25, y + 4.5, textPrueba, {align: 'center'});
        if(sacarRecordDePrueba(prue.prueba.prueba, prue.genero, detallesTorneo, recordsMundial, recordsNacional).mundial && sacarRecordDePrueba(prue.prueba.prueba, prue.genero, detallesTorneo, recordsMundial, recordsNacional).nacional){
          doc.setFontSize(7.3);
          doc.setFont('ABeeZee-Regular', 'normal');
          doc.text(x + 25, y + 7.5, auxRecords, {align: 'center'});
          y += 2
        }
        if(quitarEspacio){
          y += 10;
        }else{
          y += 11;
        }
        // Itera sobre los nadadores en el arreglo `data`
        planilla[prue.id - 1].forEach((nadador, indexNadador) => {
          doc.setTextColor('black');
          doc.setFontSize(7.5);
          doc.setFont('ABeeZee-Regular', 'normal');
          const textSerie = idioma === 'ingles' ? `${diccionarioEsAEn['Serie']} #${nadador.Serie}` : `Serie #${nadador.Serie}`;
  
          if (y >= doc.internal.pageSize.height - 18) {
            // Cambiar a la siguiente columna o página
            x += columnWidth;
            y = 30;
  
            if (x >= columnWidth * 3) {
              doc.addPage();
              doc.setTextColor('black');
              doc.setFontSize(15);
              doc.setFont('DMSerifText-Italic', 'italic');
              doc.text(`${doc.getNumberOfPages() < 10 ? '0' + doc.getNumberOfPages() : doc.getNumberOfPages()}`, 178, 25);
              doc.setFont('DMSerifText-Italic', 'italic');
              doc.setFontSize(11);
              doc.addImage(marco, 'png', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
              doc.addImage(logoImg, 'png', 20, 3, width, height);
              doc.text(idioma === 'ingles' ? diccionarioEsAEn['resultados'] : `resultados`, 185, 6.6);
              doc.addImage(qrResultados, 'png', 185, 7, 18, 18);
              doc.text(`${detallesTorneo.titulo !== '' ? detallesTorneo.titulo : ''}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
              doc.setFontSize(8);
              doc.setFont('ABeeZee-Italic', 'italic');
              doc.text(`${detallesTorneo.direccion}\n${detallesTorneo.jornadas[indexObj].fecha}`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
              doc.setFont('DMSerifText-Italic', 'italic');
              doc.setFontSize(10);
              doc.text((idioma === 'ingles' ? diccionarioEsAEn['Jornada'] + ' ' : 'Jornada ') + (indexObj + 1), doc.internal.pageSize.width / 2, 25, { align: 'center' });
              doc.text((idioma === 'ingles' ? diccionarioEsAEn['Calentamiento'] + ' ' : 'Calentamiento: ') + (detallesTorneo.jornadas[indexObj].calentamiento), doc.internal.pageSize.width / 3, 25, { align: 'center' });
              doc.text((idioma === 'ingles' ? diccionarioEsAEn['Inicio Competencia'] + ' ' : 'Inicio Competencia: ') + (detallesTorneo.jornadas[indexObj].inicio), doc.internal.pageSize.width / 1.5, 25, { align: 'center' });
              doc.line(20, 26, 190, 26);
              doc.line(25, 270, 190, 270);
              doc.setFontSize(8);
              doc.setFont('Times', 'italic');
              doc.addImage(qrResultadosPws, 'png', 10, 265, 10, 10);
              doc.text(`www.prowebsports.com.co`, 25, 273)
              doc.text(`PROWEBSPORTS`, 84, 273)
              doc.text(`+57 324-588-4041`, 106, 273)
              doc.text(`pws@prowebsports.com.co`, 159, 273)
              doc.addImage(logoImgPws, 'png', 195, 265, 10, 10);
              x = 10;
            }

            if(x >= columnWidth * 1 && x <= columnWidth * 2){
              x -= 5;
            }
            if(x >= columnWidth * 2 && x <= columnWidth * 3){
              x -= 4;
            }
          }
          doc.setFontSize(8.5);
          doc.setFont('DMSerifText-Regular', 'normal');
          doc.text(x, y - 1.5, textSerie);
          y += 1.5;
          nadador.nadadores.forEach((nadador, indexNadador) => {
            quitarEspacio = false;
            doc.setFontSize(7.3);
            doc.setFont('ABeeZee-Regular', 'normal');
            const carril = `${indexNadador + 1}:`;
            const nombreNadador = `${nadador.nombre}`;
            const tiempo = `${!llamados ? nadador.record ? arreglarTiempoEstandar(nadador.record) : '' : ''}`;
            const club = `${!llamados ? nadador.club.toString().toLowerCase() : acortarNombreClub(nadador.nombreclub.toString().toLowerCase(), 18)}`;
            const edad = `${!llamados ? nadador.edad[0] ? nadador.edad[0] < 10 ? '(0' + nadador.edad[0] + ')' : '(' + nadador.edad[0] + ')' : '' : ''}`;

            if (y >= doc.internal.pageSize.height - 18) {
              // Cambiar a la siguiente columna o página
              x += columnWidth;
              y = 30;
  
              if (x >= columnWidth * 3) {
                doc.addPage();
                doc.setTextColor('black');
                doc.setFontSize(15);
                doc.setFont('DMSerifText-Italic', 'italic');
                doc.text(`${doc.getNumberOfPages() < 10 ? '0' + doc.getNumberOfPages() : doc.getNumberOfPages()}`, 178, 25);
                doc.setFont('DMSerifText-Italic', 'italic');
                doc.setFontSize(11);
                doc.addImage(marco, 'png', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
                doc.addImage(logoImg, 'png', 20, 3, width, height);
                doc.text(idioma === 'ingles' ? diccionarioEsAEn['resultados'] : `resultados`, 185, 6.6);
                doc.addImage(qrResultados, 'png', 185, 7, 18, 18);
                doc.text(`${detallesTorneo.titulo !== '' ? detallesTorneo.titulo : ''}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
                doc.setFontSize(8);
                doc.setFont('ABeeZee-Italic', 'italic');
                doc.text(`${detallesTorneo.direccion}\n${detallesTorneo.jornadas[indexObj].fecha}`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
                doc.setFont('DMSerifText-Italic', 'italic');
                doc.setFontSize(10);
                doc.text((idioma === 'ingles' ? diccionarioEsAEn['Jornada'] + ' ' : 'Jornada ') + (indexObj + 1), doc.internal.pageSize.width / 2, 25, { align: 'center' });
                doc.text((idioma === 'ingles' ? diccionarioEsAEn['Calentamiento'] + ' ' : 'Calentamiento: ') + (detallesTorneo.jornadas[indexObj].calentamiento), doc.internal.pageSize.width / 3, 25, { align: 'center' });
                doc.text((idioma === 'ingles' ? diccionarioEsAEn['Inicio Competencia'] + ' ' : 'Inicio Competencia: ') + (detallesTorneo.jornadas[indexObj].inicio), doc.internal.pageSize.width / 1.5, 25, { align: 'center' });
                doc.line(20, 26, 190, 26);
                doc.line(25, 270, 190, 270);
                doc.setFontSize(8);
                doc.setFont('Times', 'italic');
                doc.addImage(qrResultadosPws, 'png', 10, 265, 10, 10);
                doc.text(`www.prowebsports.com.co`, 25, 273)
                doc.text(`PROWEBSPORTS`, 84, 273)
                doc.text(`+57 324-588-4041`, 106, 273)
                doc.text(`pws@prowebsports.com.co`, 159, 273)
                doc.addImage(logoImgPws, 'png', 195, 265, 10, 10);
                x = 10;
              }

              if(x >= columnWidth * 1 && x <= columnWidth * 2){
                x -= 5;
              }
              if(x >= columnWidth * 2 && x <= columnWidth * 3){
                x -= 4;
              }

            }
            doc.setFontSize(7.3);
            doc.setFont('ABeeZee-Regular', 'normal');
            if(marcarLinea && usuario.nombreclub === nadador.nombreclub[0]){
              const padding = 1; // Espacio de relleno alrededor del texto
              const textWidth = 56 + tiempo.length;
              const textHeight = 3.7; // Altura aproximada del texto
              // Definir el color de resaltado (en formato RGB)
              doc.setFillColor(color.r, color.g, color.b);
              // Dibujar un rectángulo detrás del texto
              doc.rect(x - padding, y - textHeight + padding, textWidth + padding * 2, textHeight, 'F');
            }
            doc.setTextColor('black');
            doc.text(x, y, carril);
            doc.text(nombreNadador, !llamados ? x + 7.5 : x + 3 , y, {align: 'left'});
            doc.text(edad, x + 2.5, y, {align: 'left'});
            doc.text(club, !llamados ? x + 48 : x + 43, y, {align: 'left'});
            doc.text(tiempo, x + 55, y, {align: 'left'});
            y += 3.1;
          });
          y += 2;
        });
      })
      if (indexObj < pruebasCompletas.length - 1) {
        doc.addPage(); // Agregar una nueva página si no es la última planilla
        newPage = false; // Restablecer la variable
        x = 10; // Restablecer la posición X // Agregar una nueva página si no es la última planilla
        y = 30;
      }
    });
      
    doc.setProperties({
      title: "Programación " + clubPropietario
    });
  }

  const generatedPdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(generatedPdfBlob);
  setPdfUrl(pdfUrl);
}

export function pdfTiempos(resultados, setPdfUrl, detallesTorneo, usuario, nPrueba, nPuestos){
    const index = resultados.findIndex(obj => obj.id === parseFloat(parseFloat(nPrueba - 1).toFixed(1)));
    const data = index === -1 ? resultados : resultados.slice(index);
    const doc = new jsPDF({
      format: 'letter',
    });
    const logoImg = new Image();
    const qrResultados = new Image();
    const qrResultadosPws = new Image();
    const logoImgPws = new Image();
    const marco = new Image();
    logoImg.src = require('../img/img_logo.png');
    qrResultados.src = require('../img/qr-resultados.png');
    qrResultadosPws.src = require('../img/qr-resultados-pws.png');
    logoImgPws.src = require('../img/img-logo-factura.png');
    marco.src = require('../img/img-marco.png');
    const width = 22;
    const height = 22;
    const columnWidth = doc.internal.pageSize.width / 3;
    
    let x = 10;
    let y = 30;

    if(data.length === 0){
      doc.setFontSize(11);
      doc.setFont('Times', 'italic');
      doc.text(usuario.nombreclub + '\nSi aun no puedes ver los tiempos, es posible que el torneo de ' + clubPropietario + ' aún no empieza.', doc.internal.pageSize.width / 2, 38, { align: 'center' });
    }

    doc.setFontSize(15);
    doc.setFont('DMSerifText-Italic', 'italic');
    doc.text(`${doc.getNumberOfPages() < 10 ? '0' + doc.getNumberOfPages() : doc.getNumberOfPages()}`, 178, 25);
    doc.setFont('DMSerifText-Italic', 'italic');
    doc.setFontSize(11);
    doc.addImage(marco, 'png', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
    doc.addImage(logoImg, 'png', 20, 3, width, height);
    doc.text(`resultados`, 185, 6.6);
    doc.addImage(qrResultados, 'png', 185, 7, 18, 18);
    doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
    doc.setFontSize(8);
    doc.setFont('ABeeZee-Italic', 'italic');
    doc.text(`${detallesTorneo.direccion}\nResultados`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
    doc.line(20, 26, 204, 26);
    doc.line(25, 270, 190, 270);
    doc.setFontSize(8);
    doc.setFont('Times', 'italic');
    doc.addImage(qrResultadosPws, 'png', 10, 265, 10, 10);
    doc.text(`www.prowebsports.com.co`, 25, 273)
    doc.text(`PROWEBSPORTS`, 84, 273)
    doc.text(`+57 324-588-4041`, 106, 273)
    doc.text(`pws@prowebsports.com.co`, 159, 273)
    doc.addImage(logoImgPws, 'png', 195, 265, 10, 10);

    data.forEach((obj, indexObj) => {
      doc.setFontSize(12);
      doc.setFont('DMSerifText-Italic', 'italic');
      const text = `${obj.id + 1} ${obj.categoria}`;
      const textPrueba = `${obj.prueba.prueba}`;
      if (y >= doc.internal.pageSize.height - 18) {
        // Cambiar a la siguiente columna o página
        x += columnWidth;
        y = 30;
  
        if (x >= columnWidth * 3) {
          doc.addPage();
          doc.setFontSize(15);
          doc.setFont('DMSerifText-Italic', 'italic');
          doc.text(`${doc.getNumberOfPages() < 10 ? '0' + doc.getNumberOfPages() : doc.getNumberOfPages()}`, 178, 25);
          doc.setFont('DMSerifText-Italic', 'italic');
          doc.setFontSize(11);
          doc.addImage(marco, 'png', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
          doc.addImage(logoImg, 'png', 20, 3, width, height);
          doc.text(`resultados`, 185, 6.6);
          doc.addImage(qrResultados, 'png', 185, 7, 18, 18);
          doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
          doc.setFontSize(8);
          doc.setFont('ABeeZee-Italic', 'italic');
          doc.text(`${detallesTorneo.direccion}\nResultados`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
          doc.line(20, 26, 204, 26);
          doc.line(25, 270, 190, 270);
          doc.setFontSize(8);
          doc.setFont('Times', 'italic');
          doc.addImage(qrResultadosPws, 'png', 10, 265, 10, 10);
          doc.text(`www.prowebsports.com.co`, 25, 273)
          doc.text(`PROWEBSPORTS`, 84, 273)
          doc.text(`+57 324-588-4041`, 106, 273)
          doc.text(`pws@prowebsports.com.co`, 159, 273)
          doc.addImage(logoImgPws, 'png', 195, 265, 10, 10);
          x = 10;
        }

        if(x >= columnWidth * 1 && x <= columnWidth * 2){
          x -= 5;
        }
        if(x >= columnWidth * 2 && x <= columnWidth * 3){
          x -= 4;
        }
      }

      doc.setFontSize(12);
      doc.setFont('DMSerifText-Italic', 'italic');
      doc.text(x + 25, y, text, {align: 'center'});
      doc.text(x + 25, y + 4.5, textPrueba, {align: 'center'});
      y += 8;
        obj.nadadores.slice(0, nPuestos !== '' ? parseInt(nPuestos) : obj.nadadores.length).forEach((nadador, indexNadador) => {
          doc.setFontSize(7.3); 
          doc.setFont('ABeeZee-Regular', 'normal');
          const nadadorText = `${indexNadador + 1}: ${nadador.nombre}`;
          const tiempo = `${nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : arreglarTiempoEstandar(nadador.tiempo)}` 
          const club = nadador.club.toString().toLowerCase();
    
          if (y >= doc.internal.pageSize.height - 18) {
            // Cambiar a la siguiente columna o página
            x += columnWidth;
            y = 30;
    
            if (x >= columnWidth * 3) {
              doc.addPage();
              doc.setFontSize(15);
              doc.setFont('DMSerifText-Italic', 'italic');
              doc.text(`${doc.getNumberOfPages() < 10 ? '0' + doc.getNumberOfPages() : doc.getNumberOfPages()}`, 178, 25);
              doc.setFont('DMSerifText-Italic', 'italic');
              doc.setFontSize(11);
              doc.addImage(marco, 'png', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
              doc.addImage(logoImg, 'png', 20, 3, width, height);
              doc.text(`resultados`, 185, 6.6);
              doc.addImage(qrResultados, 'png', 185, 7, 18, 18);
              doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
              doc.setFontSize(8);
              doc.setFont('ABeeZee-Italic', 'italic');
              doc.text(`${detallesTorneo.direccion}\nResultados`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
              doc.line(20, 26, 204, 26);
              doc.line(25, 270, 190, 270);
              doc.setFontSize(8);
              doc.setFont('Times', 'italic');
              doc.addImage(qrResultadosPws, 'png', 10, 265, 10, 10);
              doc.text(`www.prowebsports.com.co`, 25, 273)
              doc.text(`PROWEBSPORTS`, 84, 273)
              doc.text(`+57 324-588-4041`, 106, 273)
              doc.text(`pws@prowebsports.com.co`, 159, 273)
              doc.addImage(logoImgPws, 'png', 195, 265, 10, 10);
              x = 10;
            }

            if(x >= columnWidth * 1 && x <= columnWidth * 2){
              x -= 5;
            }
            if(x >= columnWidth * 2 && x <= columnWidth * 3){
              x -= 4;
            }
          }
          doc.setFontSize(7.3);
          doc.setFont('ABeeZee-Regular', 'normal');
          doc.text(x, y, nadadorText);
          doc.text(club, x + 48, y, {align: 'left'});
          doc.text(tiempo, x + 55, y, {align: 'left'});
          y += 3.1;
        });
        y += 2;
    });

    doc.setProperties({
      title: "Tiempos " + clubPropietario
    });

    const generatedPdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(generatedPdfBlob);
    setPdfUrl(pdfUrl);
}

export function pdfPapeletas(planilla, setPdfUrl, pruebasCompletas, usuario, auxPapeletas) {
  const doc = new jsPDF({
    format: 'letter',
  });
  const logoImgPws = new Image();
  const logoImgAnfi = new Image();
  logoImgPws.src = require('../img/img-logo-factura.png');
  logoImgAnfi.src = require('../img/img_logo.png');
  const width = 15.5;
  const height = 14;
  const columnWidth = doc.internal.pageSize.width / 2;
  let x = 0;
  let y = 0;

  if (planilla.length === 0) {
    doc.setFontSize(11);
    doc.setFont('Times', 'italic');
    doc.text(usuario.nombreclub + '\nLa programación aún no se ha registrado.', doc.internal.pageSize.width / 2, 48, { align: 'center' });
  } else {
    pruebasCompletas.forEach((obj, indexObj) => {
      obj.forEach((prue, indexPrue)=>{
        if (y >= doc.internal.pageSize.height - 20) {
          // Cambiar a la siguiente columna o página
          x += columnWidth;
          y = 0;
    
          if (x >= columnWidth * 2) {
            doc.addPage();
            x = 0;
          }
        }
  
        doc.setFontSize(12);
        doc.setFont('DMSerifText-Regular', 'normal');
        // Itera sobre los nadadores en el arreglo `data`
        planilla[prue.id - 1].forEach((nadadorPla, indexNadador) => {

          if (y >= doc.internal.pageSize.height - 20) {
            // Cambiar a la siguiente columna o página
            x += columnWidth;
            y = 0;
  
            if (x >= columnWidth * 2) {
              doc.addPage();
              x = 0;
            }
          }
          doc.setFontSize(12);
          doc.setFont('DMSerifText-Regular', 'normal');
          nadadorPla.nadadores.forEach((nadador, indexNadador) => {
            if (y >= doc.internal.pageSize.height - 20) {
              // Cambiar a la siguiente columna o página
              x += columnWidth;
              y = 0;
  
              if (x >= columnWidth * 2) {
                doc.addPage();
                x = 0;
              }
            }
            if(nadador.nombre.length !== 0){
              //--------------------------
              //linea izquierda
              doc.line(x, y, x, y + 69.5);
              //linea derecha
              doc.line(x + 107.5, y, x + 107.5, y + 69.5);
              //Fila 1
              doc.line(x, y, x + 107.5, y);
              //logo
              doc.addImage(logoImgAnfi, 'png', x + 4, y + 2, width, height);
              doc.addImage(logoImgPws, 'png', x + 28, y + 2, width, height);
              //linea despues del logo
              doc.line(x + 24, y, x + 24, y + 18);
              doc.line(x + 48, y, x + 48, y + 18);
              doc.setFontSize(14);
              doc.setFont('DMSerifText-Regular', 'normal');
              doc.text(x + 50, y + 10, 'EVENTO:');
              doc.text(x + 100, y + 10, `${prue.id}`, {align: 'right'});
              //--------------------------
              //Fila 2
              doc.line(x, y + 18, x + 107.5, y + 18);
              doc.setFontSize(13);
              doc.setFont('DMSerifText-Regular', 'normal');
              doc.text(x + 53.8, y + 25, `${prue.prueba.prueba}`, {align: 'center'});
              //--------------------------
              //Fila 3
              doc.setFont('DMSerifText-Regular', 'normal');
              doc.setFontSize(13);
              doc.line(x, y + 30, x + 107.5, y + 30);
              doc.text(x + 53.8, y + 38, `${nadador.nombre}`, {align: 'center'});
              //--------------------------
              //Fila 4
              doc.setFontSize(12);
              doc.setFont('DMSerifText-Regular', 'normal');
              doc.line(x, y + 43, x + 107.5, y + 43);
              doc.text(x + 3, y + 50, 'SERIE:');
              doc.text(x + 50, y + 50, `${nadadorPla.Serie}`, {align: 'right'});
              //linea mitad
              doc.line(x + 53.8, y + 43, x + 53.8, y + 55);
              doc.text(x + 57, y + 50, 'CARRIL:');
              doc.text(x + 100, y + 50, `${indexNadador + 1}`, {align: 'right'});
              //--------------------------
              //Fila 5
              doc.setFontSize(15);
              doc.line(x, y + 55, x + 107.5, y + 55);
              doc.text(x + 3, y + 64, 'TIEMPO:');
              //--------------------------
              //ultima linea abajo
              doc.line(x, y + 69.5, x + 107.5, y + 69.5);
              y += 70;
            }
          });
        });
      })
    });
      
    doc.setProperties({
      title: "Papeletas " + clubPropietario
    });
  }

  const generatedPdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(generatedPdfBlob);
  setPdfUrl(pdfUrl);
  toast.dismiss(auxPapeletas);
  toast.success('Papeletas creadas correctamente.');
}

export function pdfPruebaIndividual(resultados, detallesTorneo, setPdfUrl){
  const doc = new jsPDF({
    format: 'letter',
  });
  const logoImg = new Image();
  logoImg.src = require('../img/img_logo.png');
  const width = 22;
  const height = 22;

  // Ajusta las coordenadas para centrar el contenido
  const centerWidth = 4;
  doc.setFont('Times', 'italic', 'bold');
  doc.text(`${resultados.id + 1 + ' / ' + resultados.prueba.prueba + ' / ' + resultados.categoria}`, doc.internal.pageSize.width / 2, 40, { align: 'center' });

  // Define las columnas de la tabla
  const columns = ["Pto", "Deportista", "Edad", "Club", "T.I", "Tiempo"];

  const cellHeight = 15; // Cambia este valor para ajustar el alto de cada celda

  // Itera sobre las columnas y muestra la información en la primera fila
  columns.forEach((column, index) => {
    const yPosition = 43;
    switch (index) {
      case 0:
        doc.text(column, 10, yPosition + cellHeight / 2, { align: 'left' });
        break;
      case 1:
        doc.text(column, 22, yPosition + cellHeight / 2, { align: 'left' });
        break;
      case 2:
        doc.text(column, 90, yPosition + cellHeight / 2, { align: 'left' });
        break;
      case 3:
        doc.text(column, 105, yPosition + cellHeight / 2, { align: 'left' });
        break;
      case 4:
        doc.text(column, 122, yPosition + cellHeight / 2, { align: 'left' });
        break;
      case 5:
        doc.text(column, 138, yPosition + cellHeight / 2, { align: 'left' });
        break;
    
      default:
        break;
    }
  });

  //linea abajo de la categoria y prueba
  doc.line(50, 42, 170, 42);
  //linea debajo del encabezado
  doc.line(8, 53, 190, 53);
  doc.setFontSize(15);
  doc.setFont('Times', 'italic', '');
  // Itera sobre los nadadores y muestra la información en la tabla
  resultados.nadadores.forEach((nadador, index) => {
    const yPosition = 50 + (index + 1) * 5;
    doc.text((index + 1).toString(), centerWidth + 8, yPosition + 5, { align: 'left' });
    doc.text(nadador.nombre, centerWidth + 18, yPosition + 5, { align: 'left' });
    doc.text(nadador.edad, centerWidth + 87, yPosition + 5, { align: 'left' });
    doc.text(nadador.club, centerWidth + 100, yPosition + 5, { align: 'left' });
    doc.text(arreglarTiempoEstandar(nadador.record) !== 'NaN:NaN:NaN' ? '0.0' : arreglarTiempoEstandar(nadador.record), centerWidth + 118, yPosition + 5, { align: 'left' });
    doc.text(`${nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : arreglarTiempoEstandar(nadador.tiempo)}`, centerWidth + 135, yPosition + 5, { align: 'left' });
  });

  doc.setFontSize(10);
  doc.setFont('Times', 'italic');
  doc.text(`${doc.getNumberOfPages()}`, 200, 10)
  doc.setFont('Times', 'italic', 'bold');
  doc.setFontSize(11);
  doc.addImage(logoImg, 'png', 20, 3, width, height);
  doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
  doc.setFontSize(8);
  doc.setFont('Times', 'italic');
  doc.text(`${detallesTorneo.direccion}\nPrueba`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
  doc.line(20, 26, 190, 26);
  doc.line(20, 270, 190, 270);
  doc.setFontSize(8);
  doc.setFont('Times', 'italic');
  doc.text(`www.prowebsports.com.co`, 20, 273)
  doc.text(`pws@prowebsports.com.co`, 160, 273)
  doc.text(`PROWEBSPORTS`, 84, 273)
  doc.text(`+57 324-588-4041`, 106, 273)

  doc.setProperties({
    title: `Prueba ${resultados.id + 1 + ' / ' + resultados.prueba.prueba}`
  });

  const generatedPdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(generatedPdfBlob);
  setPdfUrl(pdfUrl);
}

function sacarReporteInscritos(pruebasCompletas, deportistas){
  const auxDeportistas = [];
  deportistas.forEach((depor)=>{
    depor.reporte = [];
    pruebasCompletas.forEach((jor, indexJor)=>{    
      depor.records.forEach((rec)=>{
        const pruebaEncontrada = jor.filter((prue)=>{return prue.prueba.id === rec.prueba && (parseInt(prue.edadInicio) <= depor.edad && parseInt(prue.edadFin) >= depor.edad) && prue.genero === depor.genero});
        if(pruebaEncontrada.length !== 0){
          depor.auxCategoria = pruebaEncontrada[0].categoria;
          pruebaEncontrada[0].jornada = indexJor + 1;
          depor.reporte.push(pruebaEncontrada[0]);
        }
      })
    })
    auxDeportistas.push(depor);
  })
  return auxDeportistas;
}

export function reporteInscritos(pruebasCompletas, usuario, deportistas, setPdfUrl, detallesTorneo){
  const data = sacarReporteInscritos(pruebasCompletas, deportistas);
  const doc = new jsPDF({
    format: 'letter',
  });
  const logoImg = new Image();
  logoImg.src = require('../img/img_logo.png');
  const width = 22;
  const height = 22;
  const columnWidth = doc.internal.pageSize.width / 2;
  
  let x = 10;
  let y = 30;
  if(data.length === 0){
    doc.setFontSize(11);
    doc.setFont('Times', 'italic');
    doc.text(usuario.nombreclub + '\nNo tienes deportistas inscritos aún.', doc.internal.pageSize.width / 2, 38, { align: 'center' });
  }
  doc.setFontSize(10);
  doc.setFont('Times', 'italic');
  doc.text(`${doc.getNumberOfPages()}`, 200, 10)
  doc.setFont('Times', 'italic', 'bold');
  doc.setFontSize(11);
  doc.addImage(logoImg, 'png', 20, 3, width, height);
  doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
  doc.setFontSize(8);
  doc.setFont('Times', 'italic');
  doc.text(`${detallesTorneo.direccion}\nReporte Inscritos`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
  doc.line(20, 26, 190, 26);
  doc.line(20, 270, 190, 270);
  doc.setFontSize(8);
  doc.setFont('Times', 'italic');
  doc.text(`www.prowebsports.com.co`, 20, 273)
  doc.text(`pws@prowebsports.com.co`, 160, 273)
  doc.text(`PROWEBSPORTS`, 84, 273)
  doc.text(`+57 324-588-4041`, 106, 273)
  data.forEach((obj, indexObj) => {
    const text1 = `${indexObj + 1}) ${obj.auxCategoria ? obj.auxCategoria : 'Sin pruebas aún'} Doc: ${obj.identificacion}`;
    const text2 = `${obj.nombre}`
    if (y >= doc.internal.pageSize.height - 15) {
      // Cambiar a la siguiente columna o página
      x += columnWidth;
      y = 30;

      if (x >= columnWidth * 2){
        doc.addPage();
        doc.setFontSize(10);
        doc.setFont('Times', 'italic');
        doc.text(`${doc.getNumberOfPages()}`, 200, 10)
        doc.setFont('Times', 'italic', 'bold');
        doc.setFontSize(11);
        doc.addImage(logoImg, 'png', 20, 3, width, height);
        doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
        doc.setFontSize(8);
        doc.setFont('Times', 'italic');
        doc.text(`${detallesTorneo.direccion}\nReporte Inscritos`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
        doc.line(20, 26, 190, 26);
        doc.line(20, 270, 190, 270);
        doc.setFontSize(8);
        doc.setFont('Times', 'italic');
        doc.text(`www.prowebsports.com.co`, 20, 273)
        doc.text(`pws@prowebsports.com.co`, 160, 273)
        doc.text(`PROWEBSPORTS`, 84, 273)
        doc.text(`+57 324-588-4041`, 106, 273)
        x = 10;
      }
    }
    doc.setFontSize(12);
    doc.setFont('Times', 'italic', 'bold');
    doc.text(x, y + 1, text1);
    doc.text(text2, x, y + 5);
    y += 8;
    obj.reporte.forEach((prue, indexPrue) => {
      doc.setFontSize(8);
      doc.setFont('Helvetica', 'italic');
      const pruebaText = `${prue.id}`;
      const textTiempos = `${arreglarTiempoEstandar(obj.records[indexPrue].record)}`
      const textPrueba = `${prue.prueba.prueba}`
      const textJornada = `Jor: ${prue.jornada}`
      if (y >= doc.internal.pageSize.height - 15) {
        // Cambiar a la siguiente columna o página
        x += columnWidth;
        y = 30;

        if (x >= columnWidth * 2) {
          doc.addPage();
          doc.setFontSize(10);
          doc.setFont('Times', 'italic');
          doc.text(`${doc.getNumberOfPages()}`, 200, 10)
          doc.setFont('Times', 'italic', 'bold');
          doc.setFontSize(11);
          doc.addImage(logoImg, 'png', 20, 3, width, height);
          doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
          doc.setFontSize(8);
          doc.setFont('Times', 'italic');
          doc.text(`${detallesTorneo.direccion}\nReporte Inscritos`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
          doc.line(20, 26, 190, 26);
          doc.line(20, 270, 190, 270);
          doc.setFontSize(8);
          doc.setFont('Times', 'italic');
          doc.text(`www.prowebsports.com.co`, 20, 273)
          doc.text(`pws@prowebsports.com.co`, 160, 273)
          doc.text(`PROWEBSPORTS`, 84, 273)
          doc.text(`+57 324-588-4041`, 106, 273)
          x = 10;
        }
      }
      doc.text(x, y, pruebaText);
      doc.text(textTiempos, x + 10, y, { align: 'left' });
      doc.text(textJornada, x + 23, y, { align: 'left' });
      doc.text(textPrueba, x + 33, y, { align: 'left' });
      y += 3;
    });
  })
  doc.setProperties({
    title: "Reporte Inscripciones"
  });
  const generatedPdfBlob = doc.output('blob');
  const pdfUrl = URL.createObjectURL(generatedPdfBlob);
  setPdfUrl(pdfUrl);
}

export function clubesInscritosToPdf(clubes, setPdfUrl, detallesTorneo, usuario, setDescripcion){
  const data = clubes;
    const doc = new jsPDF({
      format: 'letter',
    });
    const logoImg = new Image();
    logoImg.src = require('../img/img_logo.png');
    const width = 22;
    const height = 22;
    const columnWidth = doc.internal.pageSize.width / 2;
    
    let x = 10;
    let y = 30;

    if(data.length === 0){
      doc.setFontSize(11);
      doc.setFont('Times', 'italic');
      doc.text(usuario.nombreclub + '\nNo hay clubes inscritos aún.', doc.internal.pageSize.width / 2, 38, { align: 'center' });
    }

    doc.setFontSize(10);
    doc.setFont('Times', 'italic');
    doc.text(`${doc.getNumberOfPages()}`, 200, 10)
    doc.setFont('Times', 'italic', 'bold');
    doc.setFontSize(11);
    doc.addImage(logoImg, 'png', 20, 3, width, height);
    doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
    doc.setFontSize(8);
    doc.setFont('Times', 'italic');
    doc.text(`${detallesTorneo.direccion}\nReporte Clubes Inscritos`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
    doc.line(20, 26, 190, 26);
    doc.line(20, 270, 190, 270);
    doc.setFontSize(8);
    doc.setFont('Times', 'italic');
    doc.text(`www.prowebsports.com.co`, 20, 273)
    doc.text(`pws@prowebsports.com.co`, 160, 273)
    doc.text(`PROWEBSPORTS`, 84, 273)
    doc.text(`+57 324-588-4041`, 106, 273)

    data.forEach((obj, indexObj) => {
      if(obj.nadadores.length !== 0){
        const text1 = `${obj.nombreclub} - ${obj.pago ? '' : 'Debe'}`;
        if (y >= doc.internal.pageSize.height - 15) {
          // Cambiar a la siguiente columna o página
          x += columnWidth;
          y = 30;
    
          if (x >= columnWidth * 1){
            doc.addPage();
            doc.setFontSize(14);
            doc.setFont('Times', 'italic');
            doc.text(`${doc.getNumberOfPages()}`, 200, 10)
            doc.setFont('Times', 'italic', 'bold');
            doc.setFontSize(11);
            doc.addImage(logoImg, 'png', 20, 3, width, height);
            doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
            doc.setFontSize(8);
            doc.setFont('Times', 'italic');
            doc.text(`${detallesTorneo.direccion}\nReporte Clubes Inscritos`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
            doc.line(20, 26, 190, 26);
            doc.line(20, 270, 190, 270);
            doc.setFontSize(8);
            doc.setFont('Times', 'italic');
            doc.text(`www.prowebsports.com.co`, 20, 273)
            doc.text(`pws@prowebsports.com.co`, 160, 273)
            doc.text(`PROWEBSPORTS`, 84, 273)
            doc.text(`+57 324-588-4041`, 106, 273)
            x = 10;
          }
        }
  
        doc.setFontSize(15);
        doc.setFont('Times', 'italic', 'bold');
        doc.text(x + 90, y + 2, text1, {align: 'center'});
        y += 6;
        if(obj.directivos){
          if(obj.directivos.length !== 0){
            obj.directivos.forEach((dire)=>{
              const directivo = `${dire.nombre} : ${dire.rol} : ${dire.cel}`;
              
              doc.setFontSize(14);
              doc.text(x, y + 2, directivo, {align: 'left'});
              y += 5;
            })
  
            y += 4;
          }
        }else{
          y += 2;
        }
  
        obj.nadadores.forEach((nadador, indexNadador) => {
          doc.setFontSize(14);
          doc.setFont('Times', 'italic');
          const pruebaText = `${indexNadador + 1})`;
          const textNadador = `${nadador.nombre}`
          const textDoc = `${nadador.identificacion}`
          const textFechaNto = `${nadador.fechaNacimiento}`
          const textEdad = `${nadador.edad} Años`
          const textGenero = `${nadador.genero}`
          const verificacion = `${nadador.verificado === 'Verificado' ? 'Verificado' : 'No Verific.'}`
          if (y >= doc.internal.pageSize.height - 15) {
            // Cambiar a la siguiente columna o página
            x += columnWidth;
            y = 30;
    
            if (x >= columnWidth * 1) {
              doc.addPage();
              doc.setFontSize(14);
              doc.setFont('Times', 'italic');
              doc.text(`${doc.getNumberOfPages()}`, 200, 10)
              doc.setFont('Times', 'italic', 'bold');
              doc.setFontSize(11);
              doc.addImage(logoImg, 'png', 20, 3, width, height);
              doc.text(`${detallesTorneo.titulo}\n${clubPropietario}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
              doc.setFontSize(8);
              doc.setFont('Times', 'italic');
              doc.text(`${detallesTorneo.direccion}\nReporte Clubes Inscritos`, doc.internal.pageSize.width / 2, 18, { align: 'center' });
              doc.line(20, 26, 190, 26);
              doc.line(20, 270, 190, 270);
              doc.setFontSize(8);
              doc.setFont('Times', 'italic');
              doc.text(`www.prowebsports.com.co`, 20, 273)
              doc.text(`pws@prowebsports.com.co`, 160, 273)
              doc.text(`PROWEBSPORTS`, 84, 273)
              doc.text(`+57 324-588-4041`, 106, 273)
              doc.setFontSize(14);
              doc.setFont('Times', 'italic');
              x = 10;
            }
          }
          doc.text(x, y, pruebaText);
          doc.text(textNadador, (indexNadador + 1) < 10 ? x + 5: (indexNadador + 1) < 100 ? x + 7 : x + 10, y, { align: 'left' });
          doc.text(textDoc, x + 78, y, { align: 'left' });
          doc.text(textFechaNto, x + 108, y, { align: 'left' });
          doc.text(textEdad, x + 135, y, { align: 'left' });
          doc.text(textGenero, x + 155, y, { align: 'left' });
          doc.text(verificacion, x + 178, y, { align: 'left' });
          y += 6;
        });
      }
    })

    doc.setProperties({
      title: "Reporte Inscripciones De Clubes"
    });

    const generatedPdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(generatedPdfBlob);
    setDescripcion('Reporte Inscripciones De Clubes');
    setPdfUrl(pdfUrl);
}