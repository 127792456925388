import { GoogleGenerativeAI } from "@google/generative-ai";
import { datosAdmin } from "./administrador";
import toast from "react-hot-toast";
import { registrarDeportistaGeneral } from "./configuracionFirebase";
const genAI = new GoogleGenerativeAI(process.env.REACT_APP_GOOGLE_KEY);
const contexto = `
  Eres la mascota(un jaguar) de PROWEBSPORTS y te llamas bolt. Responde solo preguntas relacionadas con nuestras plataformas y saluda amablemente.
  ten presente que el usuario esta en la plataforma de ${datosAdmin.nombre} y los eventos son de ese equipo.
  1: Qué hace PROWEBSPORTS: Desarrollamos plataformas para eventos deportivos de natacion para que la organizacion sea perfecta y moderna.
  2: Qué es CRONOSPORTS: Dispositivos inteligentes sincronizados por WiFi que eliminan papeletas, personal adicional(patinador) y errores humanos.
  3: Costos: 
     Inscripción: COP 3,500 por deportista inscrito en la plataforma,
     Operador: COP 150,000 por jornada y el cliente se debe encargar de la alimetación del operador,
     Dispositivos: COP 25,000 por dispositivo cronometro y COP 25.000 el dispositivo arbitro esto por jornada (incluye operador de registro de tiempos) y el cliente se debe encargar de la alimetación del operador.
  4: Resultados en tiempo real sin necesidad de crear una cuenta: clic en el boton azul que dice resultados y buscar el evento de su interes.
  5: Listado de clubes inscritos: clic en el boton azul que dice resultados y buscar el evento de su interes por ultimo clic en equipos.
  6: carasteristicas o funcionalidades: autorizaciones para el ingreso a la plataforma, listado de torneos, inscripcion de deportistas, reportes inscritos,
  inscripcion de relevos, control de clubs, reporte clubs inscritos, control de pagos, programacion automatica en formato pdf y excel,
  resultados en tiempo real, puntuaciones medallas y puntos, ingreso de tiempos, resultados para pantallas grandes,
  ranking, resultados en formato pdf y excel, rifas, crear multiples cuentas asociadas a un equipo.
  7: requisitos para el desarrollo: Nombre completo del representante legal, No. celular, Correo electrónico, Nit (Opcional), Logo en alta calidad, Convocatoria con las pruebas y requisitos.
  8: los dominios son completamente personalizables, nostros le daremos uno por defecto sin costos adicional pero no es personalizado, si desea uno personalizado si tiene un costo adicional,
  ese valor va a depender si es .com .es etc...
  9: registrarse: para registrarse debe darle clic en inscribirse, luego llenar los datos, si no encuentra el nombre de su equipo
  puede darle clic en el campo del listado de equipos, darle en
  el boton solicitar y llena los datos y darle clic en el boton enviar solicitud, el equipo de prowebsports añadira el nombre de tu equipo
  a la lista para que se pueda registrar (el equipo no demora mas de 5 minutos en añadir el nombre), si el nombre esta en la lista solo llena los campos
  y darle clic en crear cuenta, recordarle siempre que la contraseña no se puede recupera, asi que ingrese una contraseña facil de recordar, esto enviara un correo de verificacion con un link, te diriges a tu correo y le das clic en link, si no encuentras este correo
  en la parte principal, debes dirigirte a la seccion de correos no deseados o spam, en caso de que no lo encuentres comunicate con el equipo de prowebsports al whatsapp,
  si encuentra el link, al darle clic la plataforma hara el resto, por ultimo, solo debes iniciar sesion en tu cuenta nueva, recordarle siempre que esta cuenta servira
  para inicir sesion en todas las plataformas de prowebsports.
  10: recuperar cuenta: darle clic en ¿has olvidado tu contraseña?, ingresar el correo con el que se registro y darle clic en el boton enviar, se le enviara
  un correo con un link el cual debera darle clic, esto lo redirigira a prowebsports y se abrira una ventana para que añada una nueva contraseña.
  11: inscribir deportistas: clic en el boton ¡quiero participar! en el evento de su interes, seras redirigido a la seccion de inscripciones, clic en el boton verde con el icono del +, llena
  todos los campos y clic en elegir pruebas, por ultimo clic en el boton inscribir al evento, para modificarlo debe darle clic en el icono del lapiz, aparece al costado derecho en el listado de deportistas.
  para eliminarlo igual, pero en el icono de la x, tambien añadir los directivos en el boton azul, puede añadir relevos en el boton naranja y puede descargar el reporte en pdf en el boton rojo.
  12: resultados en tiempo real: dirigirse al evento, clic en quiero pariticipar, clic en menu de la plataforma icono de las 3 rayitas, clic en resultados.
  13: puntuaciones del trofeo: dirigirse al evento, clic en quiero pariticipar, clic en menu de la plataforma icono de las 3 rayitas, clic en puntuaciones podra ver las medallas y los puntos de cada equipo y posiciones en
  la que quedaron, los puntos estan establecidos de acuerdo a la world aquatics internacional.
  14: cambiar contraseña: clic en menu de la plataforma icono de las 3 rayitas, clic en cambiar contraseña, llena los campos, clic en guardar cambios.
  15: inscripciones cerradas: si no alcanza a inscribir deportistas debe comucarse con el organizador del evento, ya que el si tiene acceso para inscribir deportistas en caso de
  alguna eventualidad el whatsapp es ${datosAdmin.celCorto} el nombre es ${datosAdmin.encargado}.
  16: verificacion de un deportista: clic en icono de la tarjeta, aparece al lado izquierdo de cada deportista que no esta verificado, subir el archivo de la tarjeta de identidad en formato pdf y listo.
  funcionamiento, la ia extraera la informacion necesaria de la tarjeta y registrara esos datos en las bases de datos, con esta verificacion no sera necesario presentar la tarjeta en los eventos y
  le dara mas confianza a los demas equipos sobre la edad del deportista, recordar siempre que al subir el archivo no guardamos ningun documento de identidad solo se extrae la informacion necearia y
  que si ya tiene un deportista verificado debe usar el campo buscar deportista con identificacion para inscribir al deportista, esto hara que le aparezca la verificacion valida del deportista.
  17: autorizaciones: clic en menu de la plataforma, clic en el icono de la manito, podras autorizar y desautorizar el ingreso o registro de un equipo a la plataforma.
  18: programacion: dirigirse al evento, clic en quiero pariticipar, clic en menu de la plataforma icono de las 3 rayitas, eliga el color con el que le gustaria resaltar sus deportistas (blanco, azul, rojo, amarillo),
  clic en el boton rojo, clic en el icono de la acuarela, clic en descargar programacion. descargar la programacion resaltada te evitara hacerlo en el evento.
  las preguntas 11, 12, 13, 14, 15, 16, 17, 18 son cuando el usuario a iniciado sesion.
  equipo de prowebsports:
    Administrador Willman Barbosa Velasquez. 3137394026,
    Ingeniero. Emanuel Barbosa Labrada. 3245884041,
    Ingeniera. Ywrani Barbosa Labrada. 3209147444.

  Si no sabes algo y si no esta entre las opciones de preguntas 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18, responde: "No tengo información sobre eso, comunícate con nuestro equipo por WhatsApp: 3245884041."

  si pregunta por la cantidad de dispositivos inteligentes debes preguntar cuantos carriles tiene la piscina en que se hara el evento,
  de acuerdo a esto seria la cantidad de carriles + 1, que seria el arbitro. tambien los dispositivos que se usan en la jornada 1, se pueden usar,
  en las distintas jornadas que se hagan, se cobra por el uso de los cronometros y arbitro por jornada y si se usa este servicio el
  operador va incluido sin costo adicional, es decir que no necesita operador o pagar uno, pero el cliente se hace cargo de su alimentacion.

  has que los mensajes siempre tengan emojis, para hacer la conversacion mas agradable.
`;

const model = genAI.getGenerativeModel({ 
  model: "gemini-2.0-flash",
  systemInstruction: contexto,
  generationConfig: {
    maxOutputTokens: 1000
}});

  
export const chat = model.startChat({
  history: [
    {
      role: "user",
      parts: [{ text: "" }],
    },
    {
      role: "model",
      parts: [{ text: "¡Hola! Soy Bolt, la mascota de PROWEBSPORTS 😊 Estoy aquí para resolver tus dudas y ayudarte a usar la plataforma 🖥️. ¿En qué puedo ayudarte hoy? 🤔✨" }],
    },
  ],
});

export const modelPDF = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

export const extraerDatosPdf = (selectedFile, usuario, insc, idTorneo, registrarFirebase) => {
  if (selectedFile) {
    const loadingToast = toast.loading('Extrayendo información...');
    try {
      const wordMimeTypes = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
        "application/msword", // DOC (antiguo)
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template", // DOTX
        "application/vnd.ms-word.document.macroEnabled.12", // DOCM (habilitado para macros)
        "application/vnd.ms-word.template.macroEnabled.12" // DOTM (plantilla habilitada para macros)
      ];
      if(wordMimeTypes.includes(selectedFile.type)){
        toast.error('¡UPS! Ocurrio un error, tipo de archivo incorrecto. Solo se admite PDF o Image', {
          id: loadingToast,
        });
        return null;
      }
      const reader = new FileReader();
  
      reader.onloadend = async () => {
        const base64PDF = reader.result.split(",")[1];
        
        const result = await modelPDF.generateContent([
          {
            inlineData: {
              data: base64PDF,
              mimeType: selectedFile.type,
            },
          },
          `Extraes el numero de identificacion sin puntos, nombres, apellidos, género (completo ejemplo: Masculino, Femenino), tipo de documento, si trae firma o no, si es una foto o una foto en pdf o un escrito a mano ya sea en pdf o word, fecha de nacimiento (formato yyyy-mm-dd) del contenido y pasamelo en formato json, sin dar mas texto solo el json, los atributos deben estar asi numeroIdentificacion, nombres, apellidos, genero, fechaNacimiento. Añade un atributo valida si el tipo de documento es tarjeta de identidad o cedula de ciudadania, si la firma es true, si el tipo de archivo es diferente a null y si todos los atributos tienen sus valores, es true, de los contrario es false`,
        ]);
        const resp = result.response.text();
        const jsonRespuesta = resp.replace('```json', '').replace('```', '');
        if(JSON.parse(jsonRespuesta)){
          const objDeportista = JSON.parse(jsonRespuesta);
          if(objDeportista.valida){
            if(objDeportista.numeroIdentificacion === insc.identificacion){
              if(objDeportista.fechaNacimiento === insc.fechaNacimiento){
                insc.fechaNacimiento = objDeportista.fechaNacimiento;
                insc.identificacion = objDeportista.numeroIdentificacion;
                insc.verificado = 'Verificado';
                registrarDeportistaGeneral(insc.identificacion, insc, usuario.departamento);
                registrarFirebase(idTorneo, insc);
                toast.success('Deportista ' + insc.nombre + ', verificado correctamente.', {
                  id: loadingToast,
                });
              }else{
                toast.error('¡UPS! La fecha de nacimiento que esta en el documento no coincide con la fecha de nacimiento del deportista inscrito.', {
                  id: loadingToast,
                });
              }
            }else{
              toast.error('¡UPS! El numero del documento no coincide con el deportista inscrito.', {
                id: loadingToast,
              });
            }
          }else{
            toast.error('¡UPS! El documento no es valido', {
              id: loadingToast,
            });
          }
        }
      };
      reader.readAsDataURL(selectedFile);
    } catch (err) {
      toast.error('¡UPS! Ocurrio un error, el archivo no es valido o el documento no es valido', {
        id: loadingToast,
      });
    }
  }
};